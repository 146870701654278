import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appDropZone]'
})
export class DropZoneDirective {

  constructor(private _toastrService : ToastrService,private sanitizer:DomSanitizer) {
  }

  @Output() dropped = new EventEmitter<any>();
  @Output() isHover = new EventEmitter<boolean>();
  @Output() DropFileName = new EventEmitter<string>();
  @Output() MultiFilesUrl = new EventEmitter<any>();
  @Output() MultiFilesArray = new EventEmitter<any>();


  @Input() fileType : string;
  @Input() currentGroup : FormGroup;
  @Input() fileSize : number = 5;
  @Input() multiFile : boolean = false;


  filesArray:any=[];
  fileName;
  fileSrc;
  uploadFileFn;


  @HostListener('drop',['$event'])
  onDrop($event){
    $event.preventDefault();
    $event.stopPropagation()
    const event = $event.dataTransfer.files;
    if(this.multiFile && event.length > 1){
      this.MultiFileUpload(event);
    } else {
      this.singleFileUpload(event);
    }
  }



  @HostListener('dragover',['$event'])
  onDropOver($event){
    $event.preventDefault();
    this.isHover.emit(true);
  }


  @HostListener('dragleave',['$event'])
  onDropleave($event){
    $event.preventDefault();
    this.isHover.emit(false);
  }


  singleFileUpload(event){
    const ValidType = event.item(0).type.includes(`${(this.fileType ? this.fileType : "image")}`);
    const validSize = this.getFileSize(event.item(0).size) <= this.fileSize;

    if(!validSize){
      this._toastrService.error(`File Size Exceed ${this.fileSize} MB`,"Error")
    }
    if(!ValidType){
      this._toastrService.error("File type not supported","Error")
    }

    if(validSize && ValidType){
      // display file
      this.previewFile(event);
      // display file
      this.dropped.emit(event.item(0));
      this.currentGroup?.controls['file'].clearValidators();
      this.currentGroup?.controls['file'].updateValueAndValidity();
      this.DropFileName.emit(event.item(0).name);
      }
  }

  MultiFileUpload(event){

    Array.from(event).map(file =>{
      if(this.checkFileListSize([file]) && this.checkFileListType([file])){
        this.filesArray.push(file)
      }
    })
    // this.filesArray = Array.from(event);


    if(!this.checkFileListType(this.filesArray)){
      this._toastrService.error("File type not supported","Error");
    }
    if(!this.checkFileListSize(this.filesArray)){
      this._toastrService.error(`File Size Exceed ${this.fileSize} MB`,"Error");
    }
   
    if(this.checkFileListType(this.filesArray) && this.checkFileListSize(this.filesArray)){
      // display file
      this.previewFile(event);
      // display files
      this.MultiFilesArray.emit(this.filesArray);
      this.currentGroup?.controls['file'].clearValidators();
      this.currentGroup?.controls['file'].updateValueAndValidity();
      // this.fileName = event.item(0).name;
      }
  }


  checkFileListType(fileList:any){
    const validType = fileList.every(file => file.type.includes(`${(this.fileType ? this.fileType : "image")}`));
    return validType;
  }

  checkFileListSize(fileList:any){
    const validSize = fileList.every(file => this.getFileSize(file.size) <= this.fileSize) ;
    return validSize;
  }

  getFileSize(size){
    let fileSize;
    if(size){
        fileSize = parseFloat((size / (1024 * 1024)).toFixed(2));
        return fileSize;
    }
  }

  previewFile(event){
    // //////console.log("preview",this.multiFile)
    if(event.length > 1){
      this.MultiFilePreview(event)
    } else {
      this.singleFilePreview(event)
    }
  }

  singleFilePreview(event){
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const file = reader.result;
      // for svg image proccessed as unsafe;
      this.fileSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`);
    }, false);

    if (event.item(0)) {
      reader.readAsDataURL(event.item(0));
    }
  }


  fileListUrl  = [];

  MultiFilePreview(event){
    for(let i=0; i < event.length ; i++){
        const reader = new FileReader();
        reader.readAsDataURL(event[i]);
        reader.addEventListener("load", (event) => {
          const file = event.target.result;
          // for svg image proccessed as unsafe;
          this.fileListUrl.push(this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`));
        }, false);
    }
    this.MultiFilesUrl.emit(this.fileListUrl)
  }

}