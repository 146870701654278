import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropZoneDirective } from './directives/appdropzone.directive';
import { ToastrModule } from 'ngx-toastr';
import { ToggleClassDirective } from './directives/toggle-class.directive';
import { NoDataComponent } from './components/no-data/no-data.component';
import { SignalLogoComponent } from './components/signal-logo/signal-logo.component';
import { isAllowedToShowPipe } from './pipes/premission.pipe';
import { LoaderComponent } from './components/loader/loader.component';


@NgModule({
  declarations: [
    UploadFileComponent,
    DropZoneDirective,
    ToggleClassDirective,
    NoDataComponent,
    SignalLogoComponent,
    LoaderComponent
    
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule
  ],
  exports:[
    UploadFileComponent,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    DropZoneDirective,
    ToastrModule,
    ToggleClassDirective,
    NoDataComponent,
    LoaderComponent
  ]
})
export class CoreModule { }
