import { Component, OnInit, Output ,EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit , OnChanges{
  isHovered : boolean = false;
  constructor(private sanitizer:DomSanitizer,private _toastrService : ToastrService) { }

  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileSrcUrl = new EventEmitter<any>();
  @Input() imageUploaded : boolean = false;
  @Input() currentGroup : FormGroup;
  @Input() type : string;
  @Input() imageUrl : string;
  @Input() fileType : string;
  @Input() fileSize : number = 5;
  @Input() multiFile : boolean = false;
  @Input() label : string = 'file-upload';
  @Input() formControlLabel : string = 'file';
  @Input() clearName : boolean = false;
  



  fileName;
  fileSrc;


  filesArray : any = [];

  ngOnInit() {
    this.currentGroup.controls[this.formControlLabel].clearValidators();
    this.currentGroup.controls[this.formControlLabel].updateValueAndValidity();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.clearName?.currentValue){
      this.fileName = ""
    }
  }
  isHover(isHover){
    this.isHovered = isHover;
  }

  /*
    event is equal to $event.target.files
  */

  uploadFile($event){
    let ValidType;
    const event : any = $event.target.files;
    if(this.fileType){
      ValidType = this.checkFileListType(Array.from(event))
    }
    else{
      ValidType = true;
    }
    const validSize = this.checkFileListSize(Array.from(event));

    if(!validSize){
      $event.target.value = "";
      this._toastrService.error(`File Size Exceed ${this.fileSize} MB`,"Error")
    }
    if(!ValidType){
      $event.target.value = "";
      this._toastrService.error("File type not supported","Error")
    }

    if(event && validSize && ValidType){
      if(this.multiFile){
        this.MultiFileUpload(event);
      } else {
        this.singleFileUpload(event);
      }
    }
  }


  singleFileUpload(event){
    let ValidType;
    if(this.fileType){
      ValidType = event.item(0)?.type.includes(`${(this.fileType ? this.fileType : "image") }`);
    }
    else{
      ValidType = true;
    }
    const validSize = this.getFileSize(event.item(0)?.size) <= this.fileSize;

    if(!validSize){
      this._toastrService.error(`File Size Exceed ${this.fileSize} MB`,"Error")
    }
    if(!ValidType){
      this._toastrService.error("File type not supported","Error")
    }

    if(validSize && ValidType){
      // display file
      this.previewFile(event);
      // display file
      this.fileUploaded.emit(event.item(0));
      this.currentGroup.controls[this.formControlLabel].clearValidators();
      this.currentGroup.controls[this.formControlLabel].updateValueAndValidity();
      this.fileName = event.item(0).name;
      }
  }

  MultiFileUpload(event){
    Array.from(event).map(file =>{
      if(this.checkFileListSize([file]) && this.checkFileListType([file])){
        this.filesArray.push(file)
      }
    })
    // this.filesArray = Array.from(event);


    if(!this.checkFileListType(this.filesArray)){
      this._toastrService.error("File type not supported","Error");
    }
    if(!this.checkFileListSize(this.filesArray)){
      this._toastrService.error(`File Size Exceed ${this.fileSize} MB`,"Error");
    }


   
    if(this.checkFileListType(this.filesArray) && this.checkFileListSize(this.filesArray)){
      // //////console.log("valid files")
      // display file
      this.previewFile(event);
      // display files
      this.fileUploaded.emit(this.filesArray);
      this.currentGroup.controls[this.formControlLabel].clearValidators();
      this.currentGroup.controls[this.formControlLabel].updateValueAndValidity();
      // this.fileName = event.item(0).name;
      }
  }

  singleFilePreview(event){
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const file = reader.result;
      // for svg image proccessed as unsafe;
      this.fileSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`);
        this.fileSrcUrl.emit(this.fileSrc);
    }, false);


    
    if (event.item(0)) {
      reader.readAsDataURL(event.item(0));
    }
  }


  fileListUrl  = [];

  MultiFilePreview(event){
    for(let i=0; i < event.length ; i++){
        const reader = new FileReader();
        reader.readAsDataURL(event[i]);
        reader.addEventListener("load", (event) => {
          const file = event.target.result;
          // for svg image proccessed as unsafe;
          this.fileListUrl.push(this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`));
        }, false);
    }
  }


  checkFileListType(fileList:any){
    const validType = fileList.every(file => file?.type.includes(`${(this.fileType ? this.fileType : "image")}`));
    // fileList.every(file => //////console.log(file?.type));
    // //////console.log(validType)
    return validType;
  }

  checkFileListSize(fileList:any){
    const validSize = fileList.every(file => this.getFileSize(file?.size) <= this.fileSize) ;
    return validSize;
  }


  setFileName(name){
    this.fileName = name;
  }


  getFileSize(size){
    let fileSize;
    if(size){
      fileSize = parseFloat((size / (1024 * 1024)).toFixed(2));
      return fileSize;
    }
  }



  previewFile(event){
    if(this.multiFile){
      this.MultiFilePreview(event)
    } else {
      this.singleFilePreview(event)
    }
  }


  trackFn(index){
    return index;
  }

  removeFile(index){
    // remove item form formArray
    this.filesArray.splice(index,1);
    // emmit new value to parent component
    this.fileUploaded.emit(this.filesArray);
    // preview files
    this.fileListUrl.splice(index,1);
  }

  setFileListUrl($event){
    this.fileListUrl = $event;
  }

  setFileArray($event){
    this.fileUploaded.emit($event)
    this.filesArray = $event;
  }

  dropped($event){
    this.fileUploaded.emit($event)
  }

}