import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../enums/role';
import { AuthenticationService } from '../services/auth.service';

@Pipe({
  name: 'isAllowedToShowPipe'
})
export class isAllowedToShowPipe implements PipeTransform {
  constructor(private authService : AuthenticationService){

  }
  transform(premissions: any[], itemType:any,role:any): unknown {
    const userPremissions = this.authService.userPremissions;
    if(premissions?.length > 0 && role === Role.User){
      if(itemType === 'section'){
        return premissions?.some((element)=> userPremissions?.includes(element))
      } else {
        return premissions?.every((element)=> userPremissions?.includes(element))
      }
     
    } else if(!premissions && role === Role.Admin) {
      return true;
    } else if(!premissions && role === Role.User) {
      return false;
    }
  }

}
