import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { storageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private defaultLang : string;
  private currentLang : string;
  currentLangObs = new BehaviorSubject(null);

  
  public languages: string[] = ['en', 'ar'];

  constructor(public translate: TranslateService, private storageService: storageService) {
    let browserLang;
    this.translate.addLangs(this.languages);

    if (this.storageService.getItem('langCode')) {
      browserLang = this.storageService.getItem('langCode');
    }
    else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : 'en');
  }

  // public setLanguage(lang) {
  //   this.translate.use(lang);
  //   this.cookieService.set('lang', lang);
  // }



  setLanguage(langCode : string){
    // this._coreLoadingScreenService.show();
    this.currentLang = langCode;
    this.currentLangObs.next(langCode);
    document.documentElement.setAttribute("lang",langCode);
    this.translate.use(langCode);
    langCode === "en" ? document.documentElement.setAttribute("dir","ltr") : document.documentElement.setAttribute("dir","rtl");
    this.storageService.setItem('langCode', langCode);
    if(langCode === "en"){
      this.removeCSSFile();
    }
    this.changeCSSFile(langCode);
    
  }

  setDefaultLang(langCode:string){
    this.setLanguage(this.defaultLang);
    this.currentLangObs.next(this.defaultLang);
    this.translate.setDefaultLang(this.defaultLang);
    localStorage.setItem("langCode",this.defaultLang);
  }


  changeCSSFile(langCode : string){
    const cssFile = document.querySelector("link[href*='style']");
    cssFile.setAttribute("href",`${langCode === 'en' ? 'styles.css' : 'styles-rtl.css'}`)
  }

  removeCSSFile(){
    const cssFile = document.querySelector("link[href*='styles-rtl']");
    if(cssFile){
      cssFile.remove();
    }
  }

  getDefaultLang(){
    return this.defaultLang;
  }

  getCurrentLang(){
    return this.currentLang;
  }


  getTranslation(word){
    return this.translate.instant(word);
  }

  isCurrentLangageEn() {
    return localStorage.getItem('langCode') === 'en';
  }





}
