import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  apiUrl = environment.apiUrl;

  searchResult = new BehaviorSubject([]);
  searchParams = new BehaviorSubject(null);
  FromTopbar = new BehaviorSubject(false);
  reportResult = new BehaviorSubject<any[]>(null);

  FullSearch = new BehaviorSubject(false);


  
  constructor(
    private _httpClient : HttpClient,
    private _authService : AuthenticationService,
    ) {

  }


  getReportSubject(){
    return this.reportResult
  }

  setReportSubject(reportResult){
    this.reportResult.next(reportResult);
  }


  search(search, pageSize?, page?){
    return this._httpClient.post(`${this.apiUrl}Transaction/AdvancedSearch${page ? '?page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}`,search,{headers:this._authService.authHeader})
  }

}
