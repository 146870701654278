import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManageDepartmentService {

  apiUrl = environment.apiUrl;

  constructor(
    private _authService : AuthenticationService,
    private _httpClient : HttpClient
  ) {}

  


  createDepartment(department:any){
    return this._httpClient.post(`${this.apiUrl}Admin/CreateDepartment`,department,{headers:this._authService.authHeader})
  }


  updateDepartment(department:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateDepartment`,department,{headers:this._authService.authHeader})
  }


  getOriginDepartment(){
    return this._httpClient.get(`${this.apiUrl}Admin/GetOriginDepartments`,{headers:this._authService.authHeader})
  }


  getDepartments(limit?,page?,filter?){
    if(limit && page){
      return this._httpClient.get(`${this.apiUrl}Admin/GetDepartments${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
      .pipe(
        map((departments : any) =>{
          if(departments?.results?.length > 0){
             const activeDepartment = departments?.results?.filter((item)=> item?.isDeleted !== true);
             departments.results = activeDepartment;
             return departments
          }
        })
      )
    } else {
      return this._httpClient.get(`${this.apiUrl}Admin/GetListDepartments?pageSize=1000`,{headers:this._authService.authHeader})
      .pipe(
        map((departments : any) =>{
          if(departments?.results?.length > 0){
             const activeDepartment = departments?.results?.filter((item)=> item?.isDeleted !== true);
             departments.results = activeDepartment;
             return departments
          }
        })
      )
    }
  }


  getFlatDeprtments(limit?,page?,filter?,type? , isAdmin?){
    if(type === "pagination"){
        return this._httpClient.get(`${this.apiUrl}Admin/GetFlatDepartments${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}${isAdmin ? ('&' + 'fromadmin=' + `${isAdmin}`) : ''}`,{headers:this._authService.authHeader})
        .pipe(
          map((departments : any) =>{
            if(departments?.results?.length > 0){
               const activeDepartment = departments?.results?.filter((item)=> item?.isDeleted !== true);
               departments.results = activeDepartment;
               return departments
            }
          })
        )
    } else {
      return this._httpClient.get(`${this.apiUrl}Admin/GetFlatDepartments?pageSize=${environment.apiCounter}${isAdmin ? ('&' + 'fromadmin=' + `${isAdmin}`) : ''}`,{headers:this._authService.authHeader})
      .pipe(
        map((departments : any) =>{
          if(departments?.results?.length > 0){
             const activeDepartment = departments?.results?.filter((item)=> item?.isDeleted !== true);
             departments.results = activeDepartment;
             return departments
          }
        })
      )
    }
   
  }

  getFlatDeprtmentsTwo(limit?,page?,filter?){
    //console.log(limit,page,filter);
    
    // return this._httpClient.get(`${this.apiUrl}Admin/GetFlatDepartments?pageSize=1000${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})

    return this._httpClient.get(`${this.apiUrl}Admin/GetFlatDepartments${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    .pipe(
      map((departments : any) =>{
        if(departments?.results?.length > 0){
           const activeDepartment = departments?.results?.filter((item)=> item?.isDeleted !== true);
           departments.results = activeDepartment;
           return departments
        }
      })
    )
  }

  getDepartment(id:any){
    return this._httpClient.get(`${this.apiUrl}Admin/GetDepartment/${id}`,{headers:this._authService.authHeader})
  }


  deleteDepartment(id:any){
    const params = new HttpParams().set('id' , id)
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteDepartment`,{headers:this._authService.authHeader,params:params})
  }

  getManagersUsers(){
    return this._httpClient.get(`${this.apiUrl}Account/GetManagersUsers`,{headers:this._authService.authHeader})  
  }

  showDepartment(id , showStatus){
    const params = new HttpParams().set('id' , id).set('isShowing' , showStatus)
    return this._httpClient.post(`${this.apiUrl}Admin/ShowDepartment`,{}, {headers:this._authService.authHeader,params:params})
  }

  activeDepartment(id , activeStatus){
    const params = new HttpParams().set('id' , id).set('isActive' , activeStatus)
    return this._httpClient.post(`${this.apiUrl}Admin/ActiveDepartment`,{}, {headers:this._authService.authHeader,params:params})
  }

  activeUserDepartment(id , activeStatus , departId){
    const params = new HttpParams().set('userId' , id).set('isActive' , activeStatus).set('departmentId' , departId)
    return this._httpClient.post(`${this.apiUrl}Admin/ActiveUserDepartment`,{}, {headers:this._authService.authHeader,params:params})
  }
  
}
