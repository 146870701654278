// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//BFWED1rTkqUDcE6iJBMmROl4VvR-TGNs_yKo2O5GEEI5FpDt1wonDEaW92-RD2gto_2G2RI_04Zi8Z4SCu1aTmA
export const environment = {
  production: false,
  applicationServerPublicKey: `BFWED1rTkqUDcE6iJBMmROl4VvR-TGNs_yKo2O5GEEI5FpDt1wonDEaW92-RD2gto_2G2RI_04Zi8Z4SCu1aTmA`,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  apiUrl: 'https://api.darah.signalx.42.solutions/api/',
  serverUrl: 'https://api.darah.signalx.42.solutions',
  editorApiKey: 'us2b8pbae0zudq9n05l1tgjzohwlylpf0eeixsoq85ihsdeh',
  scannerProductKey:
    't01539gIAAB1lI0Zt85KPa0M9LTL8HbVX1R9KPXeqw+IBFcRRHp3FaY8+E+B1Q1PmuXPaNj9VhKY7GDdSiA+e+6oHpZrN4ewbqrlhDAxWF3WvZFMJhjfwcCAdIBY5An/lBzzlczuqEABnQBLg3fgDzkN+e8WDkBFwBiQB5yEDGHSy2jeVl4BaD0lDwBmQBPSQAehi0Za5vgHleJpC',
  pdfBoxesOptions: [
    'toolsOverlay',
    'thumbnailControl',
    'viewControlsButton',
    'annotationPopup',
    'searchButton',
    'ribbons',
    'toolsHeader',
    'menuButton',
    'signatureToolGroupButton',
    'rubberStampToolGroupButton',
    'stampToolGroupButton',
    'fileAttachmentToolGroupButton',
    'calloutToolGroupButton',
  ],
  apiCounter: 1000,
  // clientBrandImage : "/assets/images/etihad.png",
  // clientBrandImage : "/assets/images/ipa-logo-w.png",
  clientBrandImage: '/assets/images/logos-darat/logobig-removebg.png',
  clientBrandImageSm: '/assets/images/logos-darat/logosm-removebg.png',
  // syncLicense : "ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdiXX9XdHBWTmdeU0Y=",
  syncLicense:
    'ORg4AjUWIQA/Gnt2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkdjXH1acXRRR2Vb',
  generalSyncToolbar: ['OpenOption', 'PrintOption', 'DownloadOption'],
  searchSyncToolbar: [
    'OpenOption',
    'PanTool',
    'SelectionTool',
    'SearchOption',
    'PrintOption',
    'DownloadOption',
    'UndoRedoTool',
    'AnnotationEditTool',
    'FormDesignerEditTool',
    'CommentTool',
  ],
  SyncService: 'https://pdf.stackanalytix.com/pdfviewer',
  documentService: 'https://pdf.stackanalytix.com/editor/api/DocumentEditor/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
