import { Role } from 'src/app/core/enums/role';
import { User } from 'src/app/core/models/auth.models';
import { MenuItem } from './menu.model';
import { permissions } from 'src/app/core/enums/premission';


export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DASHBOARDS.TEXT',
    //     icon: 'bx-home-circle',
    //     badge: {
    //         variant: 'info',
    //         text: 'MENUITEMS.DASHBOARDS.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
    //             link: '/',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
    //             link: '/',
    //             parentId: 2
    //         },
    //         {
    //             id: 5,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
    //             link: '/',
    //             parentId: 2
    //         },
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
    //             link: '/',
    //             parentId: 2
    //         },
    //     ]
    // },
    // {
    //     id: 7,
    //     isLayout: true
    // },
    // {
    //     id: 8,
    //     label: 'MENUITEMS.APPS.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.CALENDAR.TEXT',
    //     icon: 'bx-calendar',
    //     link: '/',
    // },
    // {
    //     id: 10,
    //     label: 'MENUITEMS.CHAT.TEXT',
    //     icon: 'bx-chat',
    //     link: '/',
        
    // },
    // {
    //     id: 11,
    //     label: 'MENUITEMS.FILEMANAGER.TEXT',
    //     icon: 'bx-file',
    //     link: '/',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.FILEMANAGER.BADGE',
    //     },
    // },
    // {
    //     id: 12,
    //     label: 'MENUITEMS.ECOMMERCE.TEXT',
    //     icon: 'bx-store',
    //     subItems: [
    //         {
    //             id: 13,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 14,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 15,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 16,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 17,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CART',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 18,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 19,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
    //             link: '/',
    //             parentId: 12
    //         },
    //         {
    //             id: 20,
    //             label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
    //             link: '/',
    //             parentId: 12
    //         },
    //     ]
    // },
    // {
    //     id: 21,
    //     label: 'MENUITEMS.CRYPTO.TEXT',
    //     icon: 'bx-bitcoin',
    //     subItems: [
    //         {
    //             id: 22,
    //             label: 'MENUITEMS.CRYPTO.LIST.WALLET',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 23,
    //             label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 24,
    //             label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 25,
    //             label: 'MENUITEMS.CRYPTO.LIST.LENDING',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 26,
    //             label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 27,
    //             label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
    //             link: '/',
    //             parentId: 21
    //         },
    //         {
    //             id: 28,
    //             label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
    //             link: '/',
    //             parentId: 21
    //         }
    //     ]
    // },
    // {
    //     id: 29,
    //     label: 'MENUITEMS.EMAIL.TEXT',
    //     icon: 'bx-envelope',
    //     subItems: [
    //         {
    //             id: 30,
    //             label: 'MENUITEMS.EMAIL.LIST.INBOX',
    //             link: '/',
    //             parentId: 29
    //         },
    //         {
    //             id: 31,
    //             label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
    //             link: '/',
    //             parentId: 29
    //         },
    //         {
    //             id: 32,
    //             label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.TEXT',
    //             badge: {
    //                 variant: 'success',
    //                 text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
    //             },
    //             parentId: 29,
    //             subItems: [
    //                 {
    //                     id:33 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BASIC',
    //                     link: '/',
    //                     parentId:32 
    //                 },
    //                 {
    //                     id:34 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.ALERT',
    //                     link: '/',
    //                     parentId:32 
    //                 },
    //                 {
    //                     id:35 ,
    //                     label: 'MENUITEMS.EMAIL.LIST.TEMPLATE.LIST.BILLING',
    //                     link: '/',
    //                     parentId:32 
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 36,
    //     label: 'MENUITEMS.INVOICES.TEXT',
    //     icon: 'bx-receipt',
    //     subItems: [
    //         {
    //             id: 37,
    //             label: 'MENUITEMS.INVOICES.LIST.INVOICELIST',
    //             link: '/',
    //             parentId: 36
    //         },
    //         {
    //             id: 38,
    //             label: 'MENUITEMS.INVOICES.LIST.INVOICEDETAIL',
    //             link: '/',
    //             parentId: 36
    //         },
    //     ]
    // },
    // {
    //     id: 39,
    //     label: 'MENUITEMS.PROJECTS.TEXT',
    //     icon: 'bx-briefcase-alt-2',
    //     subItems: [
    //         {
    //             id: 40,
    //             label: 'MENUITEMS.PROJECTS.LIST.GRID',
    //             link: '/',
    //             parentId: 38
    //         },
    //         {
    //             id: 41,
    //             label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
    //             link: '/',
    //             parentId: 38
    //         },
    //         {
    //             id: 42,
    //             label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
    //             link: '/',
    //             parentId: 38
    //         },
    //         {
    //             id: 43,
    //             label: 'MENUITEMS.PROJECTS.LIST.CREATE',
    //             link: '/',
    //             parentId: 38
    //         }
    //     ]
    // },
    // {
    //     id: 44,
    //     label: 'MENUITEMS.TASKS.TEXT',
    //     icon: 'bx-task',
    //     subItems: [
    //         {
    //             id: 45,
    //             label: 'MENUITEMS.TASKS.LIST.TASKLIST',
    //             link: '/',
    //             parentId: 44
    //         },
    //         {
    //             id: 46,
    //             label: 'MENUITEMS.TASKS.LIST.KANBAN',
    //             link: '/',
    //             parentId: 44
    //         },
    //         {
    //             id: 47,
    //             label: 'MENUITEMS.TASKS.LIST.CREATETASK',
    //             link: '/',
    //             parentId: 44
    //         }
    //     ]
    // },
    // {
    //     id: 48,
    //     label: 'MENUITEMS.CONTACTS.TEXT',
    //     icon: 'bxs-user-detail',
    //     subItems: [
    //         {
    //             id: 49,
    //             label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
    //             link: '/',
    //             parentId: 48
    //         },
    //         {
    //             id: 50,
    //             label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
    //             link: '/',
    //             parentId: 48
    //         },
    //         {
    //             id: 51,
    //             label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
    //             link: '/',
    //             parentId: 48
    //         }
    //     ]
    // },
    // {
    //     id: 52,
    //     label: 'MENUITEMS.BLOG.TEXT',
    //     icon: 'bx-file',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.EMAIL.LIST.TEMPLATE.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 53,
    //             label: 'MENUITEMS.BLOG.LIST.BLOGLIST',
    //             link: '/',
    //             parentId: 52
    //         },
    //         {
    //             id: 54,
    //             label: 'MENUITEMS.BLOG.LIST.BLOGGRID',
    //             link: '/',
    //             parentId: 52
    //         },
    //         {
    //             id: 55,
    //             label: 'MENUITEMS.BLOG.LIST.DETAIL',
    //             link: '/',
    //             parentId: 52
    //         },
    //     ]
    // },
    // {
    //     id: 56,
    //     label: 'MENUITEMS.PAGES.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 57,
    //     label: 'MENUITEMS.AUTHENTICATION.TEXT',
    //     icon: 'bx-user-circle',
    //     badge: {
    //         variant: 'success',
    //         text: 'MENUITEMS.AUTHENTICATION.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 58,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 59,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOGIN2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 60,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 61,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.REGISTER2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 62,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 63,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.RECOVERPWD2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 64,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 65,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.LOCKSCREEN2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 66,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 67,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.CONFIRMMAIL2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 68,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 69,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.EMAILVERIFICATION2',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 70,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION',
    //             link: '/',
    //             parentId: 57
    //         },
    //         {
    //             id: 71,
    //             label: 'MENUITEMS.AUTHENTICATION.LIST.TWOSTEPVERIFICATION2',
    //             link: '/',
    //             parentId: 57
    //         }
    //     ]
    // },
    // {
    //     id: 72,
    //     label: 'MENUITEMS.UTILITY.TEXT',
    //     icon: 'bx-file',
    //     subItems: [
    //         {
    //             id: 73,
    //             label: 'MENUITEMS.UTILITY.LIST.STARTER',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 74,
    //             label: 'MENUITEMS.UTILITY.LIST.MAINTENANCE',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 74,
    //             label: 'Coming Soon',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 75,
    //             label: 'MENUITEMS.UTILITY.LIST.TIMELINE',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 76,
    //             label: 'MENUITEMS.UTILITY.LIST.FAQS',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 77,
    //             label: 'MENUITEMS.UTILITY.LIST.PRICING',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 78,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR404',
    //             link: '/',
    //             parentId: 72
    //         },
    //         {
    //             id: 79,
    //             label: 'MENUITEMS.UTILITY.LIST.ERROR500',
    //             link: '/',
    //             parentId: 72
    //         },
    //     ]
    // },
    // {
    //     id: 80,
    //     label: 'MENUITEMS.COMPONENTS.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 81,
    //     label: 'MENUITEMS.UIELEMENTS.TEXT',
    //     icon: 'bx-tone',
    //     subItems: [
    //         {
    //             id: 82,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.ALERTS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 83,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.BUTTONS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 84,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CARDS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 85,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CAROUSEL',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 86,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.DROPDOWNS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 87,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GRID',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 88,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.IMAGES',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 88,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.LIGHTBOX',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 89,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.MODALS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 90,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.RANGESLIDER',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 91,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.PROGRESSBAR',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 91,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.PLACEHOLDER',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 92,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.SWEETALERT',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 93,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TABS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 94,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.TYPOGRAPHY',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 95,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.VIDEO',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 96,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.GENERAL',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 97,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.COLORS',
    //             link: '/',
    //             parentId: 81
    //         },
    //         {
    //             id: 98,
    //             label: 'MENUITEMS.UIELEMENTS.LIST.CROPPER',
    //             link: '/',
    //             parentId: 81
    //         },
    //     ]
    // },
    // {
    //     id: 99,
    //     label: 'MENUITEMS.FORMS.TEXT',
    //     icon: 'bxs-eraser',
    //     badge: {
    //         variant: 'danger',
    //         text: 'MENUITEMS.FORMS.BADGE',
    //     },
    //     subItems: [
    //         {
    //             id: 100,
    //             label: 'MENUITEMS.FORMS.LIST.ELEMENTS',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 101,
    //             label: 'MENUITEMS.FORMS.LIST.LAYOUTS',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 102,
    //             label: 'MENUITEMS.FORMS.LIST.VALIDATION',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 103,
    //             label: 'MENUITEMS.FORMS.LIST.ADVANCED',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 104,
    //             label: 'MENUITEMS.FORMS.LIST.EDITOR',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 105,
    //             label: 'MENUITEMS.FORMS.LIST.FILEUPLOAD',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 106,
    //             label: 'MENUITEMS.FORMS.LIST.REPEATER',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 107,
    //             label: 'MENUITEMS.FORMS.LIST.WIZARD',
    //             link: '/',
    //             parentId: 99
    //         },
    //         {
    //             id: 108,
    //             label: 'MENUITEMS.FORMS.LIST.MASK',
    //             link: '/',
    //             parentId: 99
    //         }
    //     ]
    // },
    // {
    //     id: 109,
    //     icon: 'bx-list-ul',
    //     label: 'MENUITEMS.TABLES.TEXT',
    //     subItems: [
    //         {
    //             id: 110,
    //             label: 'MENUITEMS.TABLES.LIST.BASIC',
    //             link: '/',
    //             parentId: 109
    //         },
    //         {
    //             id: 111,
    //             label: 'MENUITEMS.TABLES.LIST.ADVANCED',
    //             link: '/',
    //             parentId: 109
    //         }
    //     ]
    // },
    // {
    //     id: 112,
    //     icon: 'bxs-bar-chart-alt-2',
    //     label: 'MENUITEMS.CHARTS.TEXT',
    //     subItems: [
    //         {
    //             id: 113,
    //             label: 'MENUITEMS.CHARTS.LIST.APEX',
    //             link: '/',
    //             parentId: 112
    //         },
    //         {
    //             id: 114,
    //             label: 'MENUITEMS.CHARTS.LIST.CHARTJS',
    //             link: '/',
    //             parentId: 112
    //         },
    //         {
    //             id: 115,
    //             label: 'MENUITEMS.CHARTS.LIST.CHARTIST',
    //             link: '/',
    //             parentId: 112
    //         },
    //         {
    //             id: 116,
    //             label: 'MENUITEMS.CHARTS.LIST.ECHART',
    //             link: '/',
    //             parentId: 112
    //         }
    //     ]
    // },
    // {
    //     id: 117,
    //     label: 'MENUITEMS.ICONS.TEXT',
    //     icon: 'bx-aperture',
    //     subItems: [
    //         {
    //             id: 118,
    //             label: 'MENUITEMS.ICONS.LIST.BOXICONS',
    //             link: '/',
    //             parentId: 117
    //         },
    //         {
    //             id: 119,
    //             label: 'MENUITEMS.ICONS.LIST.MATERIALDESIGN',
    //             link: '/',
    //             parentId: 117
    //         },
    //         {
    //             id: 120,
    //             label: 'MENUITEMS.ICONS.LIST.DRIPICONS',
    //             link: '/',
    //             parentId: 117
    //         },
    //         {
    //             id: 121,
    //             label: 'MENUITEMS.ICONS.LIST.FONTAWESOME',
    //             link: '/',
    //             parentId: 117
    //         },
    //     ]
    // },
    // {
    //     id: 122,
    //     label: 'MENUITEMS.MAPS.TEXT',
    //     icon: 'bx-map',
    //     subItems: [
    //         {
    //             id: 123,
    //             label: 'MENUITEMS.MAPS.LIST.GOOGLEMAP',
    //             link: '/',
    //             parentId: 122
    //         }
    //     ]
    // },
    // {
    //     id: 124,
    //     label: 'MENUITEMS.MULTILEVEL.TEXT',
    //     icon: 'bx-share-alt',
    //     subItems: [
    //         {
    //             id: 125,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.1',
    //             link: '#',
    //             parentId: 124
    //         },
    //         {
    //             id: 126,
    //             label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.2',
    //             parentId: 124,
    //             subItems: [
    //                 {
    //                     id: 127,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.1',
    //                     parentId: 126,
    //                 },
    //                 {
    //                     id: 128,
    //                     label: 'MENUITEMS.MULTILEVEL.LIST.LEVEL1.LEVEL2.2',
    //                     parentId: 126,
    //                 }
    //             ]
    //         },
    //     ]
    // }
    {
        id: 1,
        label: 'MENUITEMS.orgMangement.TEXT',
        role : [Role.Admin],
        icon: 'fa-light fa-building',
        awesomeIcon:true,
        // badge: {
        //     variant: 'info',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        subItems: [
            {
                id: 2,
                label: 'MENUITEMS.orgMangement.LIST.OrganizationalStructure',
                link: '/organizationStructure',
                parentId: 1
            },
            {
                id: 3,
                label: 'MENUITEMS.orgMangement.LIST.Departments',
                link: '/departments',
                parentId: 1
            },
            {
                id: 4,
                label: 'MENUITEMS.orgMangement.LIST.Counter',
                link: '/setCounter',
                parentId: 1
            }
        ]
    },
    {
        id: 2,
        label: 'MENUITEMS.empolyeesManagement.TEXT',
        icon: 'fa-light fa-user-group',
        awesomeIcon:true,
        // badge: {
        //     variant: 'info',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        role : [Role.Admin],
        subItems: [
            {
                id: 3,
                label: 'MENUITEMS.empolyeesManagement.LIST.Employees',
                link: '/employeesManagement',
                parentId: 2
            },
            {
                id: 4,
                label: 'MENUITEMS.empolyeesManagement.LIST.Roles',
                link: '/rolesManagement',
                parentId: 2
            }
        ]
    },
    {
        id: 3,
        label: 'MENUITEMS.listsManagement.TEXT',
        link: '/Lookups',
        icon: 'fa-light fa-list-ul',
        awesomeIcon:true,
        role : [Role.Admin],
    },
    {
        id: 4,
        label: 'MENUITEMS.templatesManagement.TEXT',
        link: '/templates-management',
        role : [Role.Admin],
        icon: 'fa-light fa-file',
        awesomeIcon:true,
    },
    {
        id: 5,
        icon: 'fa-light fa-building',
        label: 'MENUITEMS.externalEntities.TEXT',
        link: '/externalEntities',
        awesomeIcon:true,
        role : [Role.Admin],
    },
    // {
    //     id: 7,
    //     label: 'إنشاء جديد',
    //     link: '/inbound',
    //     parentId: 6,
    //     icon: 'fa-light fa-circle-plus',
    //     awesomeIcon:true,
    //     role : [Role.User],
    //     subItems: [
            
    //     ]
    // },
    // {
    //     id: 7,
    //     icon: 'fa-file-plus',
    //     awesomeIcon:true,
    //     label: 'إنشاء جديد',
    //     role : [Role.User],
    //     isTitle: true,
    //     subItems: [
            
    //     ]
    // },
    {
        id: 9,
        label: 'إنشاء وارد',
        link: '/inbound',
        icon: 'operate-transaction-icon fa-solid fa-arrow-down',
        permissions : [...permissions.inbound],
        awesomeIcon:true,
        contentType:"item",
        role : [Role.User],

    },
    // {
    //     id: 10,
    //     label: 'صادر داخلي جديد',
    //     link: '/internal-outbound',
    //     icon: 'fa-light fa-network-wired',
    //     awesomeIcon:true,
    //     role : [Role.User],
    // },
    {
        id: 11,
        label: 'إنشاء صادر',
        link: '/outbound',
        icon: 'operate-transaction-icon fa-solid fa-arrow-up',
        contentType:"section",
        permissions : [...permissions.internalOutbound,...permissions.Outbound,permissions.outboundDraft],
        awesomeIcon:true,
        role : [Role.User],
    },
    // {
    //     id: 12,
    //     label: 'مسودة صادر جديد',
    //     link: '/draft',
    //     icon: 'fa-light fa-inbox-out',
    //     awesomeIcon:true,
    //     role : [Role.User],

    // },
    {
        id: 6,
        icon: 'fa-mailbox',
        awesomeIcon:true,
        label: 'الصناديق',
        role : [Role.User],
        contentType:"section",
        permissions : [...permissions.inbox,...permissions.secretaryBox,...permissions.copies,...permissions.outboundBox,...permissions.archiveBox,permissions.sendBox],
        isTitle: true,
    },
    {
        id: 8,
        label: 'الصندوق',
        link: '/inbox',
        permissions : [...permissions.inbox],
        badge: {
            variant: 'danger',
            text : "" ,
            index : 0,
            name: "inboxCounter"
        },
        contentType:"item",
        icon: 'fa-light fa-box',
        awesomeIcon:true,
        role : [Role.User]
    },
    {
        id: 22,
        label: 'المسودات',
        link: '/draft-box',
        permissions : [...permissions.draftBox],
        badge: {
            variant: 'danger',
            text : "",
            index : 6,
            name : "draftCounter"
        },
        icon: 'follow-icon fa-light fa-pen-field',
        awesomeIcon:true,
        contentType:"item",
        role : [Role.User]
    },
    {
        id: 16,
        label: 'المرسلة',
        link: '/sent',
        permissions : [...permissions.sendBox],
        icon: 'fa-light fa-paper-plane-top',
        awesomeIcon:true,
        contentType:"item",

        role : [Role.User]
    },
    {
        id: 14,
        label: 'النسخ',
        link: '/copies',
        permissions : [...permissions.copies],
        badge: {
            variant: 'danger',
            text : "",
            index : 3,
            name : "copiesCounter"
        },
        icon: 'fa-light fa-copy',
        awesomeIcon:true,
        contentType:"item",

        role : [Role.User]
    },
    {
        id: 21,
        label: 'المتابعة',
        link: '/following',
        permissions : [...permissions.followingBox],
        badge: {
            variant: 'danger',
            text : "",
            index : 4,
            name : "followCounter"
        },
        icon: 'follow-icon fa-light fa-users-viewfinder',
        awesomeIcon:true,
        contentType:"item",

        role : [Role.User]
    },
    {
        id: 15,
        label: 'الصادر',
        link: '/outbound-box',
        permissions : [...permissions.outboundBox],
        badge: {
            variant: 'danger',
            text : "",
            index : 5,
            name : "outCounter"
        },
        icon: 'fa-light fa-square-this-way-up',
        awesomeIcon:true,
        contentType:"item",

        role : [Role.User]
    },
    {
        id: 13,
        label: 'الإدارة',
        link: '/secretary-box',
        permissions : [...permissions.secretaryBox],
        badge: {
            variant: 'danger',
            text : "",
            index : 2,
            name : "deapartCounter"
        },
        icon: 'fa-light fa-box-check',
        contentType:"item",

        awesomeIcon:true,
        role : [Role.User]
    },    
    {
        id: 25,
        label: 'صندوق المدير',
        link: '/managerBox',
        permissions : [...permissions.managerBox],
        badge: {
            variant: 'danger',
            text : "" ,
            index : 1,
            name: "inboxCounter"
        },
        contentType:"item",
        icon: 'fa-light fa-box',
        awesomeIcon:true,
        role : [Role.User]
    },
    {
        id: 20,
        icon: 'fa-circle-ellipsis',
        awesomeIcon:true,
        label: 'المزيد',
        role : [Role.User],
        permissions : [...permissions.deliveryStatement,...permissions.tasks,...permissions.reports],
        
        contentType:"section",
        subItems: [
            {
                id: 18,
                icon: 'fa-light fa-file-import',
                awesomeIcon:true,
                label: 'بيانات التسليم',
                role : [Role.User],
                permissions : [...permissions.deliveryStatement],
                // contentType:"section",
                parentId: 20,
                link: '/delivery-statement',
            }, 
            {
                id: 19,
                icon: 'fa-light fa-gauge-high',
                awesomeIcon:true,
                label: 'التقارير',
                contentType:"item",
                permissions : [...permissions.reports],
                role : [Role.User],
                parentId: 20,
                link: '/reports',
            },
            {
            id: 23,
            icon: 'fa-light fa-list-check',
            awesomeIcon:true,
            label: 'المهام',
            permissions : [...permissions.tasks],
            role : [Role.User],
            contentType:"item",
            parentId: 20,
            link: '/tasks',
            },
            {
                id: 17,
                label: 'الارشيف',
                link: '/archive',
                permissions : [...permissions.archiveBox],
                icon: 'fa-light fa-box-archive',
                awesomeIcon:true,
                contentType:"item",
                parentId: 20,
                role : [Role.User]
            },
            {
            id: 24,
            icon: 'fa-light fa-share-nodes',
            awesomeIcon:true,
            label: 'المشاركة',
            parentId: 20,
            permissions : [...permissions.copies],
            role : [Role.User],
            contentType:"item",
            link: '/share',
            }
        
        ]
    },
    
   ];

