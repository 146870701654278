<div>
        <div class="modal-header">
            <p class="titles-search">البحث المتقدم</p>
          <button type="button" class="btn-close"  aria-hidden="true" (click)="closeModal()"></button>
        </div>
        <div class="modal-body">
            <!-- <form class=""> -->
               
                <form [formGroup]="searchForm" (ngSubmit)="search()" #form="ngForm">

                        <div class="d-flex justify-content-end">
                            <button class="btn search-btn">بحث</button>
                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="">
                                    <label class="titles-name-search">الموضوع</label>
                                    <div>
                                        <input type="text" class="form-control" formControlName="subject">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label class="titles-name-search ">نوع المعاملة </label>
                                    <div class="flex-grow">
                                        <ng-select 
                                        [items]="TransactionType" 
                                        bindLabel="nameAr"
                                        bindValue="id"
                                        [multiple]="false" 
                                        [addTag]="false" 					
                                        [virtualScroll]	= "true"
                                        formControlName="transactionTypeId"
                                        (change)="changeTransactionType($event)"
                                        (clear)="clearMultiple()"
                                        >
                                        </ng-select>
        
                                        <!-- <div
                                        *ngIf="
                                            formTwo.submitted ||
                                            (f2.transactionTypeId?.errors && (f2.transactionTypeId?.dirty || f2.transactionTypeId?.touched))
                                        "
                                        class="invalid-feedback"
                                        [ngClass]="{
                                            'd-block':
                                                formTwo.submitted ||
                                                (f2.transactionTypeId?.errors && (f2.transactionTypeId?.dirty || f2.transactionTypeId?.touched))
                                        }"
                                    >
                                            <div *ngIf="f2.transactionTypeId?.errors?.required">
                                                نوع المعاملة مطلوب
                                            </div>
                                        </div> -->
                                    </div>
                            </div>
                            </div>

                            <div class="col-md-3">
                                <div class="">
                                    <label class="titles-name-search">رقم المعاملة</label>
                                    <div>
                                        <input type="text" class="form-control" formControlName="transactionNumber">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <label class="titles-name-search">الحالة</label>
                                <div class="d-flex align-items-center" style="height:37px;">
                                    <span class="mx-1">
                                        <input [id]="hasDate" type="checkbox" [checked]="hasDate" class="form-check-input" (change)="selectHasDate($event)" />
                                        <label class="form-check-label mx-1" [for]="hasDate">فيها موعد</label>
                                    </span>
                                    <span class="mx-1">
                                        <input id="received" type="checkbox"  [checked]="receivedByEmployee" class="form-check-input"  (change)="isRecieved($event)"/>
                                        <label class="form-check-label mx-1" for="received">تم إستلامه</label>
                                    </span>
                                </div>
                            </div>



                            <div class="col-md-3 mb-2">
                                <div class="">
                                    <label class="titles-name-search">سبب الإحالة</label>
                                    <div>
                                        <input type="text" class="form-control" formControlName="forwordReason">
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3 mb-2">
                                <div class="">
                                    <label class="titles-name-search">الإدارة</label>
                                    <div>
                                        <ng-select  [items]="internalDepartments" bindLabel="departmentNameAr" bindValue="id" [multiple]="false"
                                            [addTag]="false" [virtualScroll]="true" formControlName="internalDepartment"
                                            (change)="getMultiple($event)" (clear)="clearMultiple()">
                                        </ng-select>
                                    </div>
                                </div>
        
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                        <label class="titles-name-search ">طريقة التسليم</label>
                                        <div class="flex-grow">
                                            <ng-select 
                                            [items]="ReceiveMethods" 
                                            bindLabel="nameAr"
                                            bindValue="id"
                                            [multiple]="false" 
                                            [addTag]="false" 	
                                            formControlName="receiveMethod"				
                                            [virtualScroll]	= "true"
                                            (change)="getMultiple($event)"
                                            (clear)="clearMultiple()"
                                            >
                                            </ng-select>
    <!--         
                                            <div
                                            *ngIf="
                                                form.submitted ||
                                                (f.receiveMethod?.errors && (f.receiveMethod?.dirty || f.receiveMethod?.touched))
                                            "
                                            class="invalid-feedback"
                                            [ngClass]="{
                                                'd-block':
                                                    form.submitted ||
                                                    (f.receiveMethod?.errors && (f.receiveMethod?.dirty || f.receiveMethod?.touched))
                                            }"
                                        >
                                                <div *ngIf="f.receiveMethod.errors?.required">
                                                    طريقة التسليم مطلوبه
                                                </div>
                                            </div> -->
                                        </div>
                                </div>
                            </div>
    
                            <!-- <div class="col-md-3 mb-2" *ngIf="inbound"> -->
    
        
                            <div class="col-md-3 mb-2" *ngIf="inbound">
                                <div class="">
                                    <label class="titles-name-search">جهة الوارد</label>
                                    <div>
                                        <ng-select [items]="exDepartments" bindLabel="nameAr" bindValue="id" [multiple]="false"
                                            [addTag]="false" [virtualScroll]="true"   formControlName="externalDepartment"				
                                            (change)="getMultiple($event)" (clear)="clearMultiple()">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>



                       
                        <!-- <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">نوع الصندوق</label>
                                <div>
                                    <ng-select [items]="boxTypes" bindLabel="nameAr" bindValue="name" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" formControlName="box"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->
    


                        <!-- <div class="col-md-3 mb-2" *ngIf="outbound && !inbound"> -->

                        <!-- <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">جهة الصادر</label>
                                <div>
                                    <ng-select [items]="exDepartments" bindLabel="nameAr" bindValue="id" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" 
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->

                      

                        <!-- <div class="col-md-3 mb-2" *ngIf="!inbound && internal"></div> -->
                        <!-- <div class="col-md-3 mb-2">
                            
                            <div class="">
                                <label class="titles-name-search">مرسلة من </label>
                                <div>
                                    <ng-select [items]="exDepartments" bindLabel="nameAr" bindValue="id" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" [searchFn]="exDepSearchFn"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->
    
                        <!-- <div class="col-md-3 mb-2" *ngIf="!inbound"> -->
    
                        <!-- <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">مرسلة إلى</label>
                                <div>
                                    <ng-select [items]="exDepartments" bindLabel="nameAr" bindValue="id" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" [searchFn]="exDepSearchFn"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div> -->
                       
    
                        <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">حالة المعاملة</label>
                                <div>
                                    <ng-select [items]="TransactionStates" bindLabel="nameAr" bindValue="state" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" formControlName="state"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-3 mb-2" *ngIf="!inbound && internal"> -->
    
                        <div class="col-md-3 mb-2" *ngIf="!inbound && internal">
                            <div class="">
                                <label class="titles-name-search">اسم المنشئ</label>
                                <div>
                                    <ng-select [items]="internalEmployee" bindLabel="fullNameAr" bindValue="fullNameAr" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" formControlName="creatorName"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">إداره المنشئ</label>
                                <div>
                                    <ng-select [items]="internalDepartments" bindLabel="departmentNameAr" bindValue="id" [multiple]="false"
                                        [addTag]="false" [virtualScroll]="true" formControlName="creatorDepartment"
                                        (change)="getMultiple($event)" (clear)="clearMultiple()">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-3 mb-2" *ngIf="inbound"> -->
                        <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">نوع الأولوية</label>
                                <div>
                                    <ng-select 
                                    [items]="PeriorityLevels" 
                                    bindLabel="descriptionAr"
                                    bindValue="id"
                                    [multiple]="false" 
                                    [addTag]="false" 					
                                    [virtualScroll]	= "true"
                                    formControlName="priorityLevelId"
                                    (clear)="clearMultiple()"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mb-2">
                            <div class="">
                                <label class="titles-name-search">نوع السرية</label>
                                <div>
                                    <ng-select 
                                    [items]="Confidentialitylevels" 
                                    bindLabel="descriptionAr"
                                    bindValue="id"
                                    [multiple]="false" 
                                    [addTag]="false" 					
                                    [virtualScroll]	= "true"
                                    formControlName="confidentialityLevelId"
                                    (change)="getMultiple($event)"
                                    (clear)="clearMultiple()"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mb-2">
                            <div class="form-group">
                                    <label class="titles-name-search">نوع خطاب المعاملة</label>
                                    <div class="flex-grow">
                                        <ng-select 
                                        [items]="letterTypes" 
                                        bindLabel="descriptionAr"
                                        bindValue="id"
                                        [multiple]="false" 
                                        [addTag]="false" 					
                                        [virtualScroll]	= "true"
                                        formControlName="letterTypeId"
                                        (change)="getMultiple($event)"
                                        (clear)="clearMultiple()"
                                        >
                                        </ng-select>
                                    </div>
                            </div>
                        </div>

                        <div class="col-md-3 mb-2">
                            <div class="form-group">
                                    <label class="titles-name-search">تصنيف المعاملة</label>
                                    <div class="flex-grow">
                                    <ng-select 
                                    [items]="transactionClassification" 
                                    bindLabel="descriptionAr"
                                    bindValue="id"
                                    [multiple]="false" 
                                    [addTag]="false" 					
                                    [virtualScroll]	= "true"
                                    formControlName="transactionClassificationId"
                                    (change)="getMultiple($event)"
                                    (clear)="clearMultiple()"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 mb-2">
                            <div class="form-group">
                                    <label class="titles-name-search">نوع الإجراء</label>
                                    <div class="flex-grow">
                                        <ng-select 
                                        [items]="transactionActions" 
                                        bindLabel="descriptionAr"
                                        formControlName="action"
                                        [multiple]="false"
                                        [addTag]="false"
                                        [virtualScroll]="true" 
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>

    
    



                        </div>

                      




                </form>
            <!-- </form> -->
        </div><!--End modal-body-->
</div>