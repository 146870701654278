<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex align-items-center flex-grow-1">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/signal_logo.png" alt="">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/SignalX.svg" alt="">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <ng-container *ngIf="user?.userType === userRole?.User" >
        <form class="position-relative app-search d-none d-md-block"  (keydown.enter)="$event.preventDefault()"  (ngSubmit)="submitForm($event)">
          <div class="position-relative">
            <input #searchInput (keyup.enter)="goToAllResult()" type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
            <span class="fa-light fa-magnifying-glass"></span>
            <button type="button" class="btn btn-advanced-search btn-primary" (click)="openModal()">
              <i class="fa-solid fa-sliders-simple"></i>
            </button>
          </div>


          <!--search list-->
          <ng-container #searchResContainer *ngIf="(searchResult?.length > 0) && resultOpen">
            <ul class="inbox-list sm-scroll search-result">

              <ng-container *ngIf="searchResult?.length > 0;else noResult ">
                <ng-container *ngFor="let transactionItem of searchResult | slice:0:10">
                  <li class="inbox-list__item">
                    <a [routerLink]="['/search/',transactionItem?.transactionId]" (click)="fullSearch()" [state]="{'location':'topbar'}" class="inbox-list__item-link">

                      <div class="inbox-account">
                        <!-- <div class="inbox-account__image">
                            <img src="../../../../assets/images/companies/img-2.png" alt="">
                        </div> -->
                        <div class="inbox-account__content">
                          <div class="content-info">
                            <div class="titles">
                              <div class="inbox-account__name">
                                {{transactionItem.destinationType === 1 ? "افراد" : transactionItem.from[0] !== undefined
                                ? transactionItem.from[0].nameAr : "" }}
                              </div>
                              <div class="inbox-account__title">
                                {{transactionItem?.transactionTypeId === 3 ? " صادر " +
                                transactionItem?.transactionType?.descriptionAr :
                                transactionItem?.transactionType?.descriptionAr}} رقم
                                {{transactionItem.transactionNumber}}
                              </div>
                              <p class="content-extra">{{transactionItem.letterType.descriptionAr}}</p>
                            </div>
                            <div class="date-and-labels">
                              <span class="date">
                                {{ transactionItem.creationDate | date:'mediumDate'}}
                              </span>

                              <span class="badge"
                                [ngClass]="transactionItem.priorityLevelId === 4 ? 'bg-warning' : 'bg-light'">{{transactionItem.priorityLevel.descriptionAr}}</span>
                            </div>
                          </div>
                          <div class="content-extra">
                            <p>
                              {{transactionItem.transactionSubject}}
                            </p>
                          </div>
                        </div>
                        <!--End inbox-account__content-->
                      </div>
                      <!--End inbox-account-->
                    </a>
                  </li>
                </ng-container>
                <li class="inbox-list__item" *ngIf="searchResult?.length > 10">
                  <a [routerLink]="['/search']" class="inbox-list__item-link show-all">عرض جميع النتائج</a>
                </li>
              </ng-container>

              <ng-template #noResult>
                <li class="inbox-list__item ">
                  <a href="" class="inbox-list__item-link">لا يوجد نتائج</a>
                </li>
              </ng-template>

            </ul>
          </ng-container>
          <!--search list-->




        </form>
        <!-- <div class="d-flex align-items-center d-none d-md-flex mx-3">
          <button class="btn btn-advanced-search btn-primary" (click)="openModal()">
            <i class="fa-solid fa-sliders-simple"></i>
          <span class="mx-2">بحث متقدم</span>
          </button>
        </div> -->
      </ng-container>
      <!-- App Search-->
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


      <div class="dropdown d-inline-flex align-items-center me-2" ngbDropdown *ngIf="user?.userType === userRole?.User">
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-help-dropdown">
          <i class="fa-light fa-question"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
         
          <a class="dropdown-item menu-title p-0" href="javascript: void(0);">
            لنكات للمساعده
          </a>

       
        </div>
      </div>

      <div class="dropdown d-inline-flex align-items-center me-2" ngbDropdown *ngIf="user?.userType === userRole?.User">
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-setting-dropdown">
          <i class="fa-light fa-wrench-simple"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <!-- <div *ngFor="let depart of departmentName">
            <div class="dropdown-item" > <i class="bx bx-user font-size-16 align-middle me-1"></i> 
              <p class="text-center">{{userName}}</p>
              <p class="text-center">{{depart?.departmentNameAr}}</p>
             </div> 
             <div class="dropdown-divider"></div>
          </div> -->
          <!-- <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a> -->
          <!-- <span
              class="badge bg-success float-end">11</span> -->
          <!-- <a class="dropdown-item d-block" ><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a> -->
            <!-- <ul class="menu-title" href="javascript: void(0);">
            تغيير اللغة 
            <li>عربي</li>
            <li>English</li>
          </ul> -->
          <a class="dropdown-item menu-title p-0" href="javascript: void(0);">
            تغيير اللغة
          </a>

          <!-- <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
          (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
          <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">{{item.text}}</span>
        </a> -->

          <a class="dropdown-item" href="javascript: void(0);" *ngFor="let item of listLang"  (click)="setLanguage(item.text, item.lang, item.flag)"> 
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12">
            <span class="align-middle">{{item.text}}</span>
            <i class="fa-solid fa-check ms-5" *ngIf="item.deafult"></i> 
          </a>
             
            <!-- <a class="dropdown-item d-flex justify-content-between" href="javascript: void(0);"> 
              <i class="fa-solid fa-language font-size-16 align-middle me-1 "></i>
              English <i class="fa-solid fa-check" *ngIf="!deafultLang"></i> </a> -->

            <div class="dropdown-divider"></div>

            <a class="dropdown-item d-flex justify-content-between" href="javascript: void(0);" (click)="setConstract()"> 
              <i class="fa-solid fa-circle-half-stroke font-size-16 align-middle me-1 "></i> 
               تعديل التباين <i class="fa-solid fa-check" *ngIf="constract"></i> </a>

            <div class="dropdown-divider"></div>
          <a class="dropdown-item d-flex justify-content-between" href="javascript: void(0);">
            <i class="fa-solid fa-minus font-size-16 align-middle me-1"></i>
            {{fontSizeDeafult}}
            <i class="fa-solid fa-plus font-size-16 align-middle me-1 "></i>
          </a>

        </div>
      </div>


      <div class="dropdown d-inline-flex align-items-center me-2" ngbDropdown *ngIf="user?.userType === userRole?.User">
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle >
          <i class="fa-solid fa-bell"></i>
          <span class="badge bg-danger rounded-pill">{{count?.isNotOpenCount}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">الإشعارات</h6>
              </div>
              <div class="col-auto">
                <a routerLink="/notifications" ngbDropdownItem class="small text-primary">مشاهدة الجميع</a>
              </div>
            </div>
          </div>


          <ul style="height: 230px;" class=" inbox-list sm-scroll">
            <li class="p-3" *ngFor="let notf of notifications" (click)="openNotification(notf)" ngbDropdownItem>
              <div class="" [ngClass]="{ unread: !notf?.isOpen }">
                <h6 class="mt-0 mb-1 text-primary fw-bold">{{notf?.titleAr}}</h6>
                    <div class="font-size-12 ">
                      <p class="mb-1">وصلتك {{notf?.descriptionAr}} رقم <span class="text-primary fw-bold">{{notf?.recoredId}}</span></p>
                      <p class="mb-0 text-muted">
                         {{notf?.sentDate | date : 'mediumDate'}} <i class="fa-solid fa-alarm-clock"></i> </p>
                    </div>
              </div>
              <div class="dropdown-divider"></div>
            </li>

          </ul>


          <!-- <div *ngFor="let notf of notifications">
            <div class="dropdown-item" >
              <div class="">
                <h6 class="mt-0 mb-1 text-primary">{{notf?.titleAr}}</h6>
                    <div class="font-size-12 ">
                      <p class="mb-1">وصلتك {{notf?.descriptionAr}} رقم {{notf?.recoredId}}</p>
                      <p class="mb-0 text-muted">
                         {{notf?.sentDate | date : 'mediumDate'}} <i class="fa-solid fa-alarm-clock"></i> </p>
                    </div>
              </div>
             
            </div>
            <div class="dropdown-divider"></div>
          </div>      -->

          <!-- <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> sw
            </a>
          </div> -->
        </div>
      </div>

      <div  *ngIf="(permissionStrings.AnalyticsDashboard | showElement) && user?.userType === userRole?.User " class="dropdown d-inline-flex align-items-center me-2">
        <button routerLink="statistics" class="btn header-item" id="page-header-stat">
          <i class="fa-light fa-chart-user"></i>
        </button>
      </div>

      <div class="active-user-drop dropdown menu-item d-inline-flex align-items-center" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user " src="assets/images/users/User.png" alt="Header Avatar">
          <div class="user-name">
            <p class="user-name-para ms-2 mb-0">{{userName}}</p>
            <p class="dep-name ms-2 mb-0 ">{{dprtName}}</p>
          </div>
          <i class="fa-light fa-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <div *ngFor="let depart of departmentName">
            <div class="dropdown-item" (click)="switchUser(depart , 'main')">
              <!-- <i class="bx bx-user font-size-16 align-middle me-1"></i>  -->
              <div class="d-flex align-items-center">
                <i class="organization-icon d-flex align-items-center font-size-1 me-2 fa-solid fa-building"></i>
                <div>
                  <p class="text-left mb-0 font-size-12 fw-bold">{{userName}}</p>
                  <p class="text-left mb-0 font-size-10 opacity-75">{{depart?.departmentNameAr}}</p>
                </div>
              </div>
             
            </div>
            <div class="dropdown-divider"></div>
          </div>
          <div *ngIf="delegations.length > 0">
            <a class="menu-title">التفويضات</a>
          <div *ngFor="let deleg of delegations">
            <div class="dropdown-item" (click)="switchUser(deleg , 'delegation')">
              <!-- <i class="bx bx-user font-size-16 align-middle me-1"></i>  -->
              <div class="d-flex align-items-center">
                <i class="organization-icon d-flex align-items-center font-size-1 me-2 fa-solid fa-building"></i>
                <div>
                  <p class="text-left mb-0 font-size-12 fw-bold">{{deleg?.fromUserFullNameAr}}</p>
                  <p class="text-left mb-0 font-size-10 opacity-75">{{deleg?.fromDepartmentNameAr}}</p>
                </div>
              </div>
             
            </div>
            <div class="dropdown-divider"></div>
          </div>

          </div>
          <a class="dropdown-item" routerLink="/contacts/profile" *ngIf="user?.userType === userRole?.User">
            <i class="me-1 fa-light fa-user font-size-16"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}
          </a>

          <div class="dropdown-divider" *ngIf="user?.userType === userRole?.User"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
              <i class="me-1 fa-light fa-power-off font-size-16"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>

        
        </div>
      </div>


    </div>
  </div>
</header>