import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
// import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { storageService } from 'src/app/core/services/storage.service';
import { AdvancedSearchComponent } from 'src/app/pages/user-pages/advanced-search/advanced-search.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/core/enums/role';
import { combineLatest, EMPTY, fromEvent, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HelperService } from 'src/app/core/helpers/helper.service';
import { SearchService } from 'src/app/core/services/search.service';
import { ManageEmployeesService } from 'src/app/core/services/manage-employees.service';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { DelegationService } from 'src/app/core/services/delegation.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { permissionsStrings } from 'src/app/core/enums/premission';
import { SignalrService } from 'src/app/core/services/signalr.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit,AfterViewInit,OnDestroy {

  _unsubscribeAll = new Subject();
  permissionStrings = permissionsStrings;

  @ViewChild('searchInput') searchInput : ElementRef;

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  arabicNumbers = /[\u0660-\u0669]/;

  searchResult = [];

  goToAllResult(){
    this.router.navigate(['/search'])
  }

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
              private authFackservice: AuthfakeauthenticationService,
              public languageService: LanguageService,
              public translate: TranslateService,
              private storageService : storageService,
              public modalService: NgbModal,
              private _helperService : HelperService,
              private _searchService : SearchService,
              private _manageEmployeesService : ManageEmployeesService,
              private _transactionService: TransactionService,
              private delegationService:DelegationService,
              private dashboardService:DashboardService,
              private signalRService:SignalrService,
              private http:HttpClient
            ) {
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' , deafult: false },
    { text: 'عربي', flag: 'assets/images/flags/sa.jpg', lang: 'ar' , deafult : true },
  ];

  openMobileMenu: boolean;
  user;
  userRole = Role;
  userName;
  dprtName;
  constract : boolean = false;
  departmentName;
  deafultLang;
  pageSize = 15;
  pageNumber = 1;
  fontSizeDeafult = 12;
  delegations = [];
  notifications;
  count;
  $searchResult = combineLatest([this._searchService.FromTopbar,this._searchService.searchResult])


  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();


  // private startHttpRequest = () => {
  //   this.http.get('https://localhost:5001/api/chart')
  //     .subscribe(res => {
  //       //console.log(res);
  //     })
  // }

  // subscribeNotifications(){
    
  //   //this.signalRService.subscribe();

  // }
  ngOnInit() {
   
    this.signalRService.getNotifications(50,1).subscribe((res:any) => {
      this.notifications = res?.results;
      //this.count = res?.totalCount;
    });

    this.signalRService.getNotificationsCount().subscribe((res) => {
      this.count = res;
    })
    this.openMobileMenu = false;
    this.element = document.documentElement;
    //this.signalRService.subscribe();
    this.signalRService.init();
    this.signalRService.startConnection();
    this.signalRService.notificationDataListener(); 
    this.signalRService.data.subscribe((data:any) => {
      //console.log("data" , data);
      if(data){
        this.notifications = data.results;
        this.signalRService.getNotificationsCount().subscribe((res) => {
          this.count = res;
        });
      }
    })
    
    this.cookieValue = this.storageService.getItem('langCode');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.user = this.authService.currentUserValue;
    ////////console.log(this.user);
    let x ;
    this._manageEmployeesService.getUserById(this.user.userId).pipe(switchMap((res:any)=> {
      this.departmentName = res.employeeSections;
      this.dprtName = res.employeeSections.filter((item => item?.departmentId === this.user?.tenantId))[0].departmentNameAr;
      this.userName = res.fullNameAr;
      this.authService.userById.next(res)

      localStorage.setItem("departmentName" , this.dprtName);
      localStorage.setItem("userName" ,this.userName);
      return this.delegationService.getDelegations()
    })).subscribe((res: any) => {      
      this.delegations = res.results.filter((item => !item?.isDeleted && item.toUserId === this.user.userId));
    });


    this.$searchResult
    .pipe(
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(
      (res) => {
        const FromTopbar = res[0];
        if(FromTopbar){
          this.searchResult = res[1]
        }
      },
      (error)=>{
        this._helperService.handleError(error)
      }
    )
  }

  setLanguage(text: string, lang: string, flag: string) {

    for(let i=0 ; i < this.listLang.length ; i++){
      if(lang === this.listLang[i].lang){
        this.listLang[i].deafult = true;
      }
      else{
        this.listLang[i].deafult = false;
      }
    }

    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  setConstract(){
    this.constract = !this.constract
  }
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
   this.authService.logout();
   this._searchService.searchResult.next([]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


  openModal() {
    //ModalComponent is component name where modal is declare
    const modalRef = this.modalService.open(AdvancedSearchComponent,{modalDialogClass:'advancedSearch'});
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }

  resultOpen : boolean = true;
  @ViewChild('searchResContainer') searchResContainer : ElementRef;
  ngAfterViewInit(): void {
   
    if(this.user['userType'] === 1){
      // search for transactions
      fromEvent(this.searchInput.nativeElement,'keyup')
      .pipe(
        debounceTime(500),
        switchMap((event:any)=>{
          return this.getSearchResult(event)
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (searchResult:any)=>{
          if(searchResult?.results){
          this.searchResult = searchResult?.results;
          }
        },
        (error) => {
          this._helperService.handleError(error)
        }
      )

      fromEvent(this.searchInput.nativeElement,'keyup')
      .pipe(
        map((event:any)=>{
         if(event.key === 'Enter'){
          return true
         } else {
          return false;
         }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (FromEnter:boolean)=>{
         if(this.searchResult.length > 0 && FromEnter){
          this.router.navigate(['/search/',this.searchResult[0].transactionId]);
          this.resultOpen = false;
         }
        },
        (error) => {
          this._helperService.handleError(error)
        }
      )

      fromEvent(this.searchInput.nativeElement,'blur')
      .pipe(
        debounceTime(500),
        tap(()=>{
          this.resultOpen = false;
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (searchResult:any)=>{
          this.searchResult = [];
          this.resultOpen = false;
        },
        (error) => {
          this._helperService.handleError(error)
        }
      )

      fromEvent(this.searchInput.nativeElement,'focus')
      .pipe(
        debounceTime(500),
        tap(()=>{
          this.resultOpen = true;
        }),
        switchMap((event:any)=>{
          return this.getSearchResult(event)
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (searchResult:any)=>{
          if(searchResult?.results){
          this.searchResult = searchResult?.results;
          }
        },
        (error) => {
          this._helperService.handleError(error)
        }
      )
    }
   
  }


  submitForm($event){
    $event.preventDefault();
  }

  getSearchResult(event){
    const term = event?.target?.value.toLocaleLowerCase();
    if(term?.trim() === ""){
      return EMPTY
    } else {
      if(this.router.url.indexOf('search') > -1){
        if(term.match(/^\d+$/)){
          this._searchService.searchParams.next({transactionNumber:+event?.target?.value});
          this._searchService.FromTopbar.next(true);
          return EMPTY;
        } else if(term.match(this.arabicNumbers)){
          const EnglishString = event?.target?.value.replace(/[\u0660-\u0669]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
          this._searchService.searchParams.next({transactionNumber:+EnglishString});
          this._searchService.FromTopbar.next(true);
          return this._searchService.search({transactionNumber:+EnglishString})
        } else {
            this._searchService.searchParams.next({subject:event?.target?.value});
            this._searchService.FromTopbar.next(true);
          return EMPTY;
        }
      } else {
        if(term.match(/^\d+$/)){
            this._searchService.searchParams.next({transactionNumber:+event?.target?.value});
            this._searchService.FromTopbar.next(true);
            return this._searchService.search({transactionNumber:+event?.target?.value})
        } else if(term.match(this.arabicNumbers)){
          const EnglishString = event?.target?.value.replace(/[\u0660-\u0669]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
          this._searchService.searchParams.next({transactionNumber:+EnglishString});
          this._searchService.FromTopbar.next(true);
          return this._searchService.search({transactionNumber:+EnglishString})
        }
        else {
            this._searchService.searchParams.next({subject:event?.target?.value});
            this._searchService.FromTopbar.next(true);
            return this._searchService.search({subject:event?.target?.value})
        }
      }
    }
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next("");
    this._unsubscribeAll.complete();
  }

  fullSearch(){
    this._searchService.FullSearch.next(true)
  }


  switchUser(dep , type){
    if(type === "main"){
      this.authService.switchUser(dep?.departmentId)
      .pipe(
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(
        (res)=>{
          this.dprtName = dep?.departmentNameAr;
          this._transactionService.modifiedTransaction.next(true);  
          this.dashboardService.modifiedCharts.next(true);  
          this.dashboardService.modifiedWidgets.next(true);  
          this._transactionService.modifiedSharesInDashboard.next(true);  
          this.dashboardService.modifiedpinned.next(true);  

          localStorage.setItem("departmentName" , this.dprtName);
          localStorage.setItem("userName" ,this.userName);      
          this.router.navigate(['/dashboard'])
          this._helperService.handleSuccess(this._helperService.generateMessage("success","تم تبديل الصلاحيات بنجاح!"));
        },
        (error)=>{
          this._helperService.handleError(error)
        }
      )
  
    }
    else{
      //////console.log(dep);


      this.authService.switchDelegations(dep?.fromDepartmentId , dep?.fromUserId)
      .pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
        //////console.log(res);
        this.dprtName = dep?.fromDepartmentNameAr;
        localStorage.setItem("departmentName" , this.dprtName);
        localStorage.setItem("userName" ,this.userName);   
        this._transactionService.modifiedTransaction.next(true);   
        this.dashboardService.modifiedCharts.next(true);    
        this.router.navigate(['/dashboard'])
        this._helperService.handleSuccess(this._helperService.generateMessage("success","تم تبديل الصلاحيات بنجاح!"));
      }, (error)=>{
            this._helperService.handleError(error)
      })
      // this.authService.switchUser(dep?.fromDepartmentId)
      // .pipe(
      //   takeUntil(this._unsubscribeAll)
      // )
      // .subscribe(
      //   (res)=>{
      //     this._transactionService.modifiedTransaction.next(true);        
      //     this.router.navigate(['/dashboard'])
      //     this._helperService.handleSuccess(this._helperService.generateMessage("success","تم تبديل الصلاحيات بنجاح!"));
      //   },
      //   (error)=>{
      //     this._helperService.handleError(error)
      //   }
      // )
  

    }    
  }


  openNotification(notification){
        
    if(notification?.notificationLink === "copies"){
      this.router.navigate([`/copies/read/${notification?.transactionId}`])
    }

    if(notification?.notificationLink === "inbox" || notification?.notificationLink === 'forword'){
      this.router.navigate([`/inbox/read/${notification?.transactionId}`])
    }

    if(notification?.notificationLink === "tasks"){
      this.router.navigate(
        ['/tasks/details-task'],
        { queryParams: { taskType: 'recieve', id: notification?.recoredId } }
      );
    }
    

    if(notification?.notificationLink === "following"){
      this.router.navigate(
        [`/following/read/receive/${notification?.transactionId}`],
        { queryParams: { followUpId: notification?.followUpId} }
      );
    }
    


    this.signalRService.openNotification(notification?.id).pipe(switchMap((res) => {
      return this.signalRService.getNotificationsCount();
    })).pipe(switchMap((res) => {
      this.count = res;
      return this.signalRService.getNotifications(50,1)
    })).subscribe((res:any) => {
      this.notifications = res?.results;
    })
  }


  goToAllNotification(){
    this.router.navigate(['/notifications']);
  }

}
