import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private toasterService : ToastrService) {
  }

  getFormData(object, useArray?:boolean, fieldName?:string){
    const formData = new FormData();
    
    Object.keys(object).map(key => {
      if(Array.isArray(object[key]) && useArray && fieldName === key){
        const details = object[key]
        
        // do somthing
        details.map((variant, index) => {

          Object.keys(variant).map(variantKey => {
            const value = typeof variant[variantKey] === 'string' ? variant[variantKey].replace(/\s+/g, ' ') : variant[variantKey]; 
            formData.append(`${key}[${index}][${variantKey}]`, value)
          })
          
        })
      } else{
        const value = typeof object[key] === 'string' ? object[key].replace(/\s+/g, ' ') : object[key]; 
        formData.append(key,value);
      }
    });

    return formData;
  }

  getFormDataFiles(object){
    const formData = new FormData();
    Object.keys(object).map((key,index) => {
        const value = typeof object[key] === 'string' ? object[key].replace(/\s+/g, ' ') : object[key]; 
        value.map((item , index) => {
          formData.append(`${key}`,item);
        })
    });
    return formData;
  }
  


  handleSuccess(res){
    this.toasterService.success(res?.message,"عملية ناجحة!")
  }

  handleError(res){
    if(res?.error?.message){
      this.toasterService.error(res?.error?.message,"تنبيه !")
    } else {
      this.toasterService.error(res?.message,"تنبيه !")
    }
  }


  generateMessage(type?:string,message?:string){
    if(type === "error"){
      return {
        error : {
          message : message
        }
      }
    } else {
      return {
        message : message
      }
    }

  }


}
