import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  apiUrl = environment.apiUrl;

  modifiedCharts = new BehaviorSubject(true);
  modifiedWidgets = new BehaviorSubject(true);
  modifiedpinned = new BehaviorSubject(true);
  modifiedNewTransaction = new BehaviorSubject(true);

  constructor(private _authService: AuthenticationService,
    private _httpClient: HttpClient) { }


  getPinList(pageNumber?) {
    return this._httpClient.get(`${this.apiUrl}Transaction/GetPinList`, { headers: this._authService.authHeader });
  }

  getWidgets() {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetWidgets`, { headers: this._authService.authHeader });
  }

  updateUserWidgets(widge) {
    return this._httpClient.post(`${this.apiUrl}Dashboard/UpdateUserWidgets`, widge, { headers: this._authService.authHeader });
  }

  getUserWidgets() {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetUserWidgets`, { headers: this._authService.authHeader });
  }

  getLatestTransactions() {
    return this._httpClient.post(`${this.apiUrl}Transaction/BasicReporting`, {}, { headers: this._authService.authHeader });
  }

  // statistics

  //علي مستوي المنظومة 
  getOrganizationChart(fromDate, toDate) {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetOrganizationChart?fromDate=${fromDate}&toDate=${toDate}`, { headers: this._authService.authHeader });

  }

  //على مستوى الإدارة والأقسام التابعة لها
  getSubDepartmentChart(fromDate, toDate, departmentId) {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetSubDepartmentChart?fromDate=${fromDate}&toDate=${toDate}&departmentId=${departmentId}`, { headers: this._authService.authHeader });
  }

  //على مستوى الإدارة والموظفين 
  getDepartmentUsersChart(fromDate, toDate, departmentId) {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetDepartmentUsersChart?fromDate=${fromDate}&toDate=${toDate}&departmentId=${departmentId}`, { headers: this._authService.authHeader });
  }

  getUserChart(fromDate, toDate) {
    return this._httpClient.get(`${this.apiUrl}Dashboard/GetUserChart?fromDate=${fromDate}&toDate=${toDate}`, { headers: this._authService.authHeader });
  }
}
