import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isAllowedToShowPipe } from './premission.pipe';
import { ShowElementPipe } from './show-element.pipe';
import { GetbarcodeobjectPipe } from './getbarcodeobject.pipe';



@NgModule({
  declarations: [
    isAllowedToShowPipe,
    ShowElementPipe,
    GetbarcodeobjectPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    isAllowedToShowPipe,
    ShowElementPipe,
    GetbarcodeobjectPipe
  ]
})
export class PipesModule { }
