import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent  {

  constructor() {

  }
 
  @Input() message : any;
  @Input() icon : any;

 
  // ngOnInit(): void {
  // }

}
