import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoaderService } from "../services/loader.service";

@Injectable()  
export class loader implements HttpInterceptor {  
    constructor(private _loaderService : LoaderService){
    }  
    requestCounter = 0;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!(request.url.indexOf(`${environment.apiUrl}`) > -1)){
            return next.handle(request)
        } else {
            this.requestCounter++;
            if(this.requestCounter > 0){
                setTimeout(()=>{
                this._loaderService.pageLoaded.next(true);
                },100)
            }
            return next.handle(request)
                   .pipe(
                      finalize(()=>{
                          this.requestCounter--
                          if(this.requestCounter === 0){
                            setTimeout(() => {
                              this._loaderService.pageLoaded.next(false);
                            }, 100);
                          }
                      })
                   )
        }
    }  
}