export enum TransactionStateEnum {
    Draft = 0,
    Reviewed = 1,
    Archive = 5,
    Published = 10,
    Delayed = 6,
    Closed = 7,
    Deleted = -1
}

export const TransactionStateEnumData = [
    {
        state : TransactionStateEnum.Draft,
        name : "Draft",
        nameAr : "مسوده"
    },
    {
        state : TransactionStateEnum.Reviewed,
        name : "Reviewed",
        nameAr : "تم الاطلاع"
    },
    {
        state : TransactionStateEnum.Archive,
        name : "Archive",
        nameAr : "حفظت"
    },
    {
        state : TransactionStateEnum.Published,
        name : "Published",
        nameAr : "أرسلت"
    },
    {
        state : TransactionStateEnum.Delayed,
        name : "Delayed",
        nameAr : "متأخره"
    },{
        state : TransactionStateEnum.Closed,
        name : "Closed",
        nameAr : "سدد قيدها"
    },{
        state : TransactionStateEnum.Deleted,
        name : "Deleted",
        nameAr : "محذوفة"
    }
]
    
