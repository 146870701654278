import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeesService {

  apiUrl = environment.apiUrl;

  constructor(
    private _authService : AuthenticationService,
    private _httpClient : HttpClient
  ) {}

  createUser(user:any){
    return this._httpClient.post(`${this.apiUrl}Account/CreateUser`,user,{headers:this._authService.authHeader})
  }

  
  updateUser(user:any){
    return this._httpClient.post(`${this.apiUrl}Account/UpdateUser`,user,{headers:this._authService.authHeader})
  }

  getAllUsers(limit?,page?,filter?){
    //console.log(limit,page,filter);
    
    if(limit && page){      
      return this._httpClient.get(`${this.apiUrl}Account/GetUsers${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'name=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    } else {    
      return this._httpClient.get(`${this.apiUrl}Account/GetUsers${filter ? ('?' + 'name=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
      //return this._httpClient.get(`${this.apiUrl}Account/GetUsers${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'name=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
  }

  getAllUsersDepartment(limit?,page?,filter?,type?){
    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Account/GetUserDepartment${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    } else {
      return this._httpClient.get(`${this.apiUrl}Account/GetUserDepartment?pageSize=${environment.apiCounter}`,{headers:this._authService.authHeader})
    }


    // if(limit && page){
    //   return this._httpClient.get(`${this.apiUrl}Account/GetUserDepartment${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {
    //   return this._httpClient.get(`${this.apiUrl}Account/GetUserDepartment${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }
  }

  getUserById(id:any){
    return this._httpClient.get(`${this.apiUrl}Account/GetUserById/${id}`,{headers:this._authService.authHeader});
  }

  getUsersByDepartmentId(departmentId , limit? ,page?){
    if(limit && page){
      return this._httpClient.get(`${this.apiUrl}Account/GetUsers?depid=${departmentId}&page=${page}&pageSize=${limit}`,{headers:this._authService.authHeader});
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Account/GetUsers?depid=${departmentId}`,{headers:this._authService.authHeader});
    }
  }

  getUsersForManager(){
    return this._httpClient.get(`${this.apiUrl}Account/GetUsersForManager`,{headers:this._authService.authHeader});
  }


  lockoutUsers(userId , activeStatus){
    const params = new HttpParams().set('userId' , userId).set('isActive' , activeStatus)
    return this._httpClient.post(`${this.apiUrl}Account/LockoutUser`,{}, {headers:this._authService.authHeader,params:params})
  }
  
  resetPassword(password){
    return this._httpClient.post(`${this.apiUrl}Account/ResetPasswordForAdmin`,password,{headers:this._authService.authHeader})
  }
}
