import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoukupsService {

  apiUrl = environment.apiUrl;

  constructor(
    private _authService : AuthenticationService,
    private _httpClient : HttpClient
  ) {}


  /* Attachements Apis
  ===========================*/
  
  // get all attachments
  getAttachments(limit?,page?,filter? , type?){
    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Admin/GetAttachmentTypes${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Admin/GetAttachmentTypes?pageSize=${environment.apiCounter}`,{headers:this._authService.authHeader})

    }
    // if(limit && page){      
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetAttachments${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {    
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetAttachments${filter ? ('?' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }
    //return this._httpClient.get(`${this.apiUrl}Admin/GetAttachments${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
  }

  getAttachmentsTypes(limit?,page?,filter?){
    return this._httpClient.get(`${this.apiUrl}Admin/Lookup/attachment_type${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
  }
  
  getAttachmentById(id){
    return this._httpClient.get(`${this.apiUrl}Admin/GetAttachmentTypes/${id}`,{headers:this._authService.authHeader})
  }

  getAttachmentsType(){
    return this._httpClient.get(`${this.apiUrl}Admin/GetAttachmentTypes`,{headers:this._authService.authHeader})
  }
  // create attachment
  createAttachment(attachment:any){
    return this._httpClient.post(`${this.apiUrl}Admin/CreateAttachmentTypes`,attachment,{headers:this._authService.authHeader})
  }

  // Update Attachment
  updateAttachment(attachment:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateAttachmentTypes`,attachment,{headers:this._authService.authHeader})
  }

  deleteAttachment(id:any){
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteAttachmentTypes?id=${id}`,{headers:this._authService.authHeader})
  }


  /* Periority Levels Apis
  ===========================*/

  getPeriorityLevels(limit?,page?,filter? , type?){
    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Admin/GetPriorityLevels${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Admin/GetPriorityLevelsLookUp${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})

    }
    // if(limit && page){
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetPriorityLevels${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {
    //   return this._httpClient.get(`${this.apiUrl}admin/GetPriorityLevelsLookUp${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }
  }

  getPeriorityLevelById(id){
    return this._httpClient.get(`${this.apiUrl}Admin/GetPriorityLevel/${id}`,{headers:this._authService.authHeader})
  }
  createPeriorityLevel(periorityData:any){
    return this._httpClient.post(`${this.apiUrl}Admin/CreatePriorityLevel`,periorityData,{headers:this._authService.authHeader})
  }

    // Update 
  updatePeriorityLevel(periorityData:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdatePriorityLevel`,periorityData,{headers:this._authService.authHeader})
  }
    deletePeriorityLevel(id:any){
    return this._httpClient.delete(`${this.apiUrl}Admin/DeletePriorityLevel?id=${id}`,{headers:this._authService.authHeader})
  }
  
  /* Transaction Classifications
  ==============================*/
  getTransactionClassifications(limit?,page?,filter? , type?){
    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionClassifications${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Admin/Lookup/transactionclassification${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    // if(page && limit){
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionClassifications${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {
    //   return this._httpClient.get(`${this.apiUrl}Admin/Lookup/transactionclassification${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }
  }
  
  getTransactionClassificationById(id){
    return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionClassification/${id}`,{headers:this._authService.authHeader})
  }

  createTransactionClassification(transData:any){
    return this._httpClient.post(`${this.apiUrl}Admin/CreateTransactionClassification`,transData,{headers:this._authService.authHeader})
  }

    // Update 
  updateTransactionClassification(transData:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateTransactionClassification`,transData,{headers:this._authService.authHeader})
  }
  
  deleteTransactionClassification(id:any){ 
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteTransactionClassification?id=${id}`,{headers:this._authService.authHeader})
  }


  /*LetterTypes
  =================================*/

  getLetterTypes(limit?,page?,filter? , type?){
    // if(limit && page){
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetLetterTypes${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {
    //   return this._httpClient.get(`${this.apiUrl}Admin/Lookup/lettertype${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }

    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Admin/GetLetterTypes${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Admin/Lookup/lettertype${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
  }
  
  getgetLetterTypeById(id){
    return this._httpClient.get(`${this.apiUrl}Admin/GetLetterType/${id}`,{headers:this._authService.authHeader})
  }

  createLetterType(letterTypeData:any){
    return this._httpClient.post(`${this.apiUrl}Admin/CreateLetterType`,letterTypeData,{headers:this._authService.authHeader})
  }

    // Update 
  updatetLetterType(letterTypeData:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateLetterType`,letterTypeData,{headers:this._authService.authHeader})
  }
  
  deleteLetterType(id:any){ 
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteLetterType?id=${id}`,{headers:this._authService.authHeader})
  }



  getConfidentialitylevel(limit?,page?,filter?){
      return this._httpClient.get(`${this.apiUrl}Admin/Lookup/confidentialitylevel${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
  }

    /*TransactionProcedure / actios
  =================================*/

  createTransactionActions(action){
    return this._httpClient.post(`${this.apiUrl}Admin/CreateTransactionProcedure` , action , {headers:this._authService.authHeader} )
  }

  getTransactionActions(limit?,page?,filter? , type?){

    if(type === "pagination"){
      return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionProcedures${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    else{
      return this._httpClient.get(`${this.apiUrl}Admin/Lookup/actions${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'search=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    }
    // if(limit && page){
    //   return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionProcedures${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // } else {
    //   return this._httpClient.get(`${this.apiUrl}Admin/Lookup/actions${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}${filter ? ('&' + 'filters=' + `${filter}`) : ''}`,{headers:this._authService.authHeader})
    // }
  }

  deleteTransactionAction(id:any){ 
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteTransactionProcedure?id=${id}`,{headers:this._authService.authHeader})
  }

  updateTransactionAction(actionData:any){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateTransactionProcedure`,actionData,{headers:this._authService.authHeader})
  }

  getTransactionActionById(id){
    return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionProcedure/${id}`,{headers:this._authService.authHeader})
  }


  // transaction counter

  getTransactionCounter(){
    return this._httpClient.get(`${this.apiUrl}Admin/GetTransactionCounter`,{headers:this._authService.authHeader})
  }

  setTransactionCounter(transactionCounter){
    return this._httpClient.put(`${this.apiUrl}Admin/UpdateTransactionCounter`, transactionCounter , {headers:this._authService.authHeader})
  }

}
