import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  serverUrl = environment.serverUrl;
  data = new BehaviorSubject<any[]>(null);
  public pushNotificationStatus = {
    isSubscribed: false,
    isSupported: false,
    isInProgress : false
  };

  swRegistration;
  constructor(private _httpClient : HttpClient , private _authService:AuthenticationService) { }

  private hubConnection: signalR.HubConnection
    public startConnection = () => {
      this.hubConnection = new signalR.HubConnectionBuilder()
                              .withUrl(this.serverUrl + "/NotificationsHub" , { transport: signalR.HttpTransportType.LongPolling })
                              .build();
      this.hubConnection
        .start()
        .then(() => {
          //console.log('Connection started');
            this.hubConnection.invoke('GetConnectionId').then((connectionId) => {
              const connection = {
                userId : JSON.parse(localStorage.getItem("currentUser")).userId,
                connectionId : connectionId,
                isOnline : true
              }
              this.updateUserConnection(connection).subscribe(() => {});
              
              // this.getNotifications().subscribe((notifications) => {
              //   //console.log(notifications);
              // })
            })
      })
        .catch(err => console.log('Error while starting connection: ' + err))
    }
    
    public notificationDataListener = () => {
      this.hubConnection.on('ReceiveNotification', (notificationId , title , titleAr , decription , decriptionAr , notificationLink , recordId) => {
        //this.data = data;
        
        
        const audio = new Audio();
        audio.src = "../../../assets/mp3/pop_up.mp3"
        audio.load();
        audio.play();     
        this.getNotifications().subscribe((notifications:any) => {
          
          this.data.next(notifications)
        })
      });
    }


    /// push notifications

    init() {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.register('/assets/sw.js')
          .then(swReg => {
            //console.log('Service Worker is registered', swReg);
            this.swRegistration = swReg;
            this.checkSubscription();
          })
          .catch(error => {
            console.error('Service Worker Error', error);
          });
        this.pushNotificationStatus.isSupported = true;
      } else {
        this.pushNotificationStatus.isSupported = false;
      }
    }
    
    checkSubscription() {
      this.swRegistration.pushManager.getSubscription()
        .then(subscription => {
          
          
          const x = JSON.parse(JSON.stringify(subscription))?.keys;
          //console.log(x);

          if(subscription === null){
            this.subscribe();
          }
          else{
            
            const notifications = {
              endpoint : subscription.endpoint,
              p256dh : JSON.parse(JSON.stringify(subscription))?.keys?.p256dh,
              auth : JSON.parse(JSON.stringify(subscription))?.keys?.auth,
              userId : JSON.parse(localStorage.getItem("currentUser")).userId
            }
  
            this.updateUserPushNotification(notifications).subscribe(() => {});
          }
          ////console.log(JSON.stringify(subscription));
          this.pushNotificationStatus.isSubscribed = !(subscription === null);
        });
    }


    subscribe() {
      this.pushNotificationStatus.isInProgress = true;
      //check the source code to get the method below
      const applicationServerKey = this.urlB64ToUint8Array(environment.applicationServerPublicKey);
      this.swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
        .then(subscription => {
          
          ////console.log(JSON.parse(JSON.stringify(subscription)));

          const notifications = {
            endpoint : subscription.endpoint,
            p256dh : JSON.parse(JSON.stringify(subscription))?.keys?.p256dh,
            auth : JSON.parse(JSON.stringify(subscription))?.keys?.auth,
            userId : JSON.parse(localStorage.getItem("currentUser")).userId
          }

          this.updateUserPushNotification(notifications).subscribe(() => {});

          this.pushNotificationStatus.isSubscribed = true;

        })
        .catch(err => {
          //console.log('Failed to subscribe the user: ', err);
        })
        .then(() => {
          this.pushNotificationStatus.isInProgress = false;
        });
    }

    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
    
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }


    updateUserPushNotification(pushNotification){
      return this._httpClient.post(`${this.serverUrl}/api/Account/UpdateUserPushNotification`, pushNotification, { headers: this._authService.authHeader })
    }

    updateUserConnection(connectionId){
      return this._httpClient.post(`${this.serverUrl}/api/Account/UpdateUserConnection`, connectionId, { headers: this._authService.authHeader })
    }

    // getNotifications(){
    //   return this._httpClient.get(`${this.serverUrl}/api/Account/GetNotifications`, { headers: this._authService.authHeader })
    // }

    getNotifications(limit?,page?){
      if(limit && page){      
        return this._httpClient.get(`${this.serverUrl}/api/Account/GetNotifications${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}`,{headers:this._authService.authHeader})
      } else {    
        return this._httpClient.get(`${this.serverUrl}/api/Account/GetNotifications`,{headers:this._authService.authHeader})
      }
    }
    
    getNotificationsCount(){
      return this._httpClient.get(`${this.serverUrl}/api/Account/GetNotificationsCount`, { headers: this._authService.authHeader })
    }

   openNotification(notificationId){
    return this._httpClient.post(`${this.serverUrl}/api/Account/OpenNotification?id=${notificationId}`, {} , { headers: this._authService.authHeader })
   }


    
}
