import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DelegationService {

  apiUrl = environment.apiUrl;

  constructor(
    private _authService : AuthenticationService,
    private _httpClient : HttpClient
  ) {}

  getDelegations(){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetDelegations?pageSize=100` , {headers:this._authService.authHeader })
  }

  getDelegationById(id){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetDelegation/${id}` , {headers:this._authService.authHeader })
  }

  createDelegation(delegation){
    return this._httpClient.post(`${this.apiUrl}Transaction/CreateDelegation` , delegation , {headers:this._authService.authHeader })
  }

  updateDelegation(delegation){
    return this._httpClient.put(`${this.apiUrl}Transaction/UpdateDelegation` , delegation , {headers:this._authService.authHeader })
  }

  deleteDelegation(id){
    return this._httpClient.delete(`${this.apiUrl}Transaction/DeleteDelegation?id=${id}` , {headers:this._authService.authHeader })
  }

}
