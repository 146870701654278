import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helpers/helper.service';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  apiUrl = environment.apiUrl;

  constructor(
    private _authService : AuthenticationService,
    private _httpClient : HttpClient,
    private _toastr : ToastrService
  ) {}


  mentionSubject = new BehaviorSubject<any[]>(null);

  draftTransaction = new BehaviorSubject(false);

  modifiedTransaction = new BehaviorSubject(true);

  navigateNewTransaction = new BehaviorSubject(null);

  NavigateWidge = new BehaviorSubject(null);

  newTransaction = new BehaviorSubject(null);

  modifiedSharesInDashboard = new BehaviorSubject(true);


  setNewTransaction(newTransaction){
    this.newTransaction.next(newTransaction);
  }  
  getNewTransaction(){
    return this.newTransaction;
  }

  getMentionSubject(){
    return this.mentionSubject
  }

  setMentionSubject(mentionSubject){
    this.mentionSubject.next(mentionSubject);
  }
  
  getDraftTransaction(){
    return this.draftTransaction
  }

  setDraftTransaction(draftTransaction){
    this.draftTransaction.next(draftTransaction);
  }

  getNavigateTransaction(){
    return this.navigateNewTransaction
  }

  setNavigateTransaction(navigateTransaction){
    this.navigateNewTransaction.next(navigateTransaction);
  }
  
  getNavigateWidge(){
    return this.NavigateWidge
  }

  setNavigateWidge(navigateWidge){
    this.NavigateWidge.next(navigateWidge);
  }

  registerInboundTransaction(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/RegisterInboundTransaction`,transaction,{headers:this._authService.authHeader})
  }

  registerInternalTransaction(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/RegisterInternalTransaction`,transaction,{headers:this._authService.authHeader})
  }

  registerOutboundTransaction(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/RegisterOutboundTransaction`,transaction,{headers:this._authService.authHeader})
  }

  uploadFile(file:any){
    return this._httpClient.post(`${this.apiUrl}Transaction/UploadAttatchmentFile`,file,{headers:this._authService.authHeader})
  }

  DownloadFile(fileId){
    const headers = new HttpHeaders({
      "Authorization": `${this._authService.token}`,
    });
    return this._httpClient.get(`${this.apiUrl}Transaction/GetAttatchmentFile?fileId=${fileId}`,{headers:headers,responseType:'arraybuffer'});
  }

  getPdfFile(file){
    const headers = new HttpHeaders({
      "Authorization": `${this._authService.token}`,
    });
    return this._httpClient.post(`${this.apiUrl}Transaction/WordToPdf`,file,{headers:headers,responseType:'arraybuffer'});
  }

  changeBarcodeOnFile(fileId,barcode){
    const headers = new HttpHeaders({
      "Authorization": `${this._authService.token}`,
    });
    return this._httpClient.post(`${this.apiUrl}Transaction/ChangeBarcode?fileId=${fileId}`,barcode,{headers:headers,responseType:'arraybuffer'});
  }


  getTransaction(id:any){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetTransactionById/${id}`,{headers:this._authService.authHeader})
  }

  getTransactionLogs(id:string){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetTransactionLogs?transactionId=${id}`,{headers:this._authService.authHeader})
  }
  getTransactionViews(id:string){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetTransactionViews?transactionId=${id}`,{headers:this._authService.authHeader})
  }

  getTransactionReferrals(id:string){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetTransactionReferrals?transactionId=${id}`,{headers:this._authService.authHeader})
  }

  filterTransaction(box:string , page? , pageSize?){
    return this._httpClient.post(`${this.apiUrl}Transaction/FilterTransaction?page=${page}&pageSize=${pageSize}` , {"box": `${box}`,
    "subject": "",
    "transactionNumber": null,
    "receiveMethod": null,
    "destinationType": null,
    "state": null,
    "externalDepartment": null,
    "department": null,
    "transactionTypeId": null,
    "confidentialityLevelId": null,
    "letterTypeId": null,
    "priorityLevelId": null,
    "transactionClassificationId": null
  } , {headers:this._authService.authHeader})
  }

  getFollowupTransaction(transaction?:any  , page? , pageSize?){
    return this._httpClient.post(`${this.apiUrl}Transaction/GetFollowupTransaction${page ? '?page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}` ,
     {
      "box" :transaction.box ? transaction.box : "",
      "subject": transaction.subject ? transaction.subject : "",
      "transactionNumber": transaction.transactionNumber ? transaction.transactionNumber : null,
      "receiveMethod": transaction.receiveMethod ? transaction.receiveMethod : null,
      "destinationType": transaction.destinationType ? transaction.destinationType : null,
      "state": transaction.state ? transaction.state : null,
      "hasDate" : transaction.hasDate ? transaction.hasDate : null,
      "externalDepartment": transaction.externalDepartment ? transaction.externalDepartment : null,
      "department": transaction.department ? transaction.department : null,
      "transactionTypeId": transaction.transactionTypeId ? transaction.transactionTypeId : null,
      "confidentialityLevelId": transaction.confidentialityLevelId ? transaction.confidentialityLevelId : null,
      "letterTypeId": transaction.letterTypeId ? transaction.letterTypeId : null,
      "priorityLevelId": transaction.priorityLevelId ? transaction.priorityLevelId : null,
      "transactionClassificationId": transaction.transactionClassificationId ? transaction.transactionClassificationId : null,
      "forUser": transaction.forUser ? {
        "userId": transaction.forUser.userId,
        "departmentId": transaction.forUser.departmentId
      } : null
    } 
  , 
  {headers:this._authService.authHeader}
  )
  }

  sentTo(transactionId:number,body?){
    return this._httpClient.post(`${this.apiUrl}Transaction/Send?transactionId=${transactionId}` , body , {headers:this._authService.authHeader})
  }

  archeive(transactionId:number , reason:string){
    return this._httpClient.post(`${this.apiUrl}Transaction/Archive` , {transactionId:transactionId , reason:reason} , {headers:this._authService.authHeader})

  }

  rejectTransaction(transactionId:number , reason:string){
    return this._httpClient.post(`${this.apiUrl}Transaction/Reject` , {transactionId:transactionId , reasonForRejection:reason} , {headers:this._authService.authHeader})

  }

  filterTransactionOpt(transaction?:any  , page? , pageSize?){
    return this._httpClient.post(`${this.apiUrl}Transaction/FilterTransaction${page ? '?page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}` ,
     {
      "box" :transaction.box ? transaction.box : "",
      "subject": transaction.subject ? transaction.subject : "",
      "transactionNumber": transaction.transactionNumber ? transaction.transactionNumber : null,
      "receiveMethod": transaction.receiveMethod ? transaction.receiveMethod : null,
      "destinationType": transaction.destinationType ? transaction.destinationType : null,
      "state": transaction.state ? transaction.state : null,
      "hasDate" : transaction.hasDate ? transaction.hasDate : null,
      "externalDepartment": transaction.externalDepartment ? transaction.externalDepartment : null,
      "department": transaction.department ? transaction.department : null,
      "transactionTypeId": transaction.transactionTypeId ? transaction.transactionTypeId : null,
      "confidentialityLevelId": transaction.confidentialityLevelId ? transaction.confidentialityLevelId : null,
      "letterTypeId": transaction.letterTypeId ? transaction.letterTypeId : null,
      "priorityLevelId": transaction.priorityLevelId ? transaction.priorityLevelId : null,
      "transactionClassificationId": transaction.transactionClassificationId ? transaction.transactionClassificationId : null,
      "copiesVisable" : transaction.copiesVisable ?  transaction.copiesVisable : false,
      "forUser": transaction.forUser ? {
        "userId": transaction.forUser.userId,
        "departmentId": transaction.forUser.departmentId
      } : null
    } 
  , 
  {headers:this._authService.authHeader}
  )
  }

  receivedTransaction(transactionId:number){
    return this._httpClient.post(`${this.apiUrl}Transaction/Received/${transactionId}` , {} , {headers:this._authService.authHeader})
  }

  editTransaction(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/Edit`,transaction,{headers:this._authService.authHeader})
  }

  rollBackTransaction(id){
    return this._httpClient.post(`${this.apiUrl}Transaction/Rollback/${id}`,{},{headers:this._authService.authHeader})
  }

  unSaveTransaction(id){
    return this._httpClient.post(`${this.apiUrl}Transaction/UnSaveTransaction/${id}`,{},{headers:this._authService.authHeader})
  }

  getDepartmentForUser(){
    return this._httpClient.get(`${this.apiUrl}Account/GetDepartmentForUser` , {headers:this._authService.authHeader})
  }

  forwardOrReplayTransaction(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/Forword` , transaction, {headers:this._authService.authHeader})
  }

  sendCopies(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/SendCopy` , transaction, {headers:this._authService.authHeader})
  }


  editAttachment(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/EditAttatchments`,transaction,{headers:this._authService.authHeader})
  }

  searchDeliveryStatement(deliveryStatement){
    return this._httpClient.post(`${this.apiUrl}Transaction/SearchDeliveryStatement`,deliveryStatement ,{headers:this._authService.authHeader})
  }

  searchPrintedDeliveryStatement(transactionNumber?, deliveryStatementId?){

    const params = {}

    if(transactionNumber){
      params['transactionNumber'] = +transactionNumber;
    }
    if(deliveryStatementId){
      params['deliveryStatementId'] =  +deliveryStatementId;      
    }
    const options = { params: params, headers:this._authService.authHeader };

    return this._httpClient.get(`${this.apiUrl}Transaction/SearchPrintedDeliveryStatement`,options)
  }

  searchArchivedDeliveryStatement(transactionNumber?, deliveryStatementId?){
     const params = {}

    if(transactionNumber){
      params['transactionNumber'] = +transactionNumber;
    }
    if(deliveryStatementId){
      params['deliveryStatementId'] =  +deliveryStatementId;      
    }
    const options = { params: params, headers:this._authService.authHeader }; 
    return this._httpClient.get(`${this.apiUrl}Transaction/SearchArchivedDeliveryStatement`,options)
  }

  getArchivedDeliveryStatementFile(filePath){
    return this._httpClient.get(`${this.apiUrl}Transaction/GeArchivedDeliveryStatementFile?filePath=${filePath}` , {headers:this._authService.authHeader , responseType:'arraybuffer' })

  }

  PrintDeliveryStatement(printArray){
    return this._httpClient.post(`${this.apiUrl}Transaction/PrintDeliveryStatement`, printArray , {headers:this._authService.authHeader })
  }

  archivedDeliveryStatement(file , deliveryStatementId){
    return this._httpClient.post(`${this.apiUrl}Transaction/ArchivedDeliveryStatement?deliveryStatementId=${deliveryStatementId}`, file , {headers:this._authService.authHeader })

  }

  getNextDeleveryStatmentId(){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetNextDeleveryStatmentId` , {headers:this._authService.authHeader })
  }

  getLatestTransactionLocation(id){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetLatestTransactionLocation?transactionId=${id}`, {headers:this._authService.authHeader })
  }

  searchReports(search, pageSize?, page?){
    return this._httpClient.post(`${this.apiUrl}Transaction/SearchReports${page ? '?page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}`,search,{headers:this._authService.authHeader})
  }

  pinnedTransaction(transactionId , location){
    return this._httpClient.post(`${this.apiUrl}Transaction/Pinned/${transactionId}/${location}`, {} , {headers:this._authService.authHeader})
  }

  removePinnedTransaction(transactionId , location){
    return this._httpClient.post(`${this.apiUrl}Transaction/RemovePinned/${transactionId}/${location}`, {} , {headers:this._authService.authHeader}) 
  }

  unFollowup(unFollowObj){
    return this._httpClient.post(`${this.apiUrl}Transaction/UnFollowup`, unFollowObj , {headers:this._authService.authHeader}) 
  }

  sendFollowupNotes(followUpNotes){
    return this._httpClient.post(`${this.apiUrl}Transaction/SendFollowupNotes`, followUpNotes , {headers:this._authService.authHeader}) 
  }

  getFollowupNotes(followUpNotes){
    return this._httpClient.post(`${this.apiUrl}Transaction/GetFollowupNotes`, followUpNotes , {headers:this._authService.authHeader}) 
  }
  
  followupAlarm(alarm){
    return this._httpClient.post(`${this.apiUrl}Transaction/FollowupAlarm`, alarm , {headers:this._authService.authHeader}) 
  }

  sendShare(transaction){
    return this._httpClient.post(`${this.apiUrl}Transaction/Share` , transaction, {headers:this._authService.authHeader})
  }

  getTranactionShares(transactionId){
    return this._httpClient.post(`${this.apiUrl}Transaction/GetTransactionShares/${transactionId}` , {}, {headers:this._authService.authHeader})
  }

  removeShareById(transactionId, shareId){
    return this._httpClient.post(`${this.apiUrl}Transaction/RemoveShareById/${transactionId}/${shareId}` , {}, {headers:this._authService.authHeader})
  }

  removeAllShare(transactionId){
    return this._httpClient.post(`${this.apiUrl}Transaction/RemoveAllShare/${transactionId}` , {}, {headers:this._authService.authHeader})
  }

  getLastTransaction(){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetLastTransaction` , {headers:this._authService.authHeader })
  }

  deleteOutboundDraftTransaction(transactionId){
    return this._httpClient.post(`${this.apiUrl}Transaction/OutDelete/${transactionId}` , {}, {headers:this._authService.authHeader})

  }

  getFollowupDetailsTransactions(transactionId){
    return this._httpClient.get(`${this.apiUrl}Transaction/GetFollowups/${transactionId}` , {headers:this._authService.authHeader})
  }

  unSeenCopies(transactionId) {
    return this._httpClient.post(`${this.apiUrl}Transaction/UnSeenCopy/${transactionId}`, {}, { headers: this._authService.authHeader })
  }

  hideCopy(transactionId) {
    return this._httpClient.post(`${this.apiUrl}Transaction/HideCopy/${transactionId}`, {}, { headers: this._authService.authHeader })
  }

  GetExternalCopiesTransaction(transaction?: any, page?, pageSize?) {
    return this._httpClient.post(`${this.apiUrl}Transaction/GetExternalCopiesTransaction${page ? '?page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}`,
      {
        "box": transaction.box ? transaction.box : "",
        "subject": transaction.subject ? transaction.subject : "",
        "transactionNumber": transaction.transactionNumber ? transaction.transactionNumber : null,
        "receiveMethod": transaction.receiveMethod ? transaction.receiveMethod : null,
        "destinationType": transaction.destinationType ? transaction.destinationType : null,
        "state": transaction.state ? transaction.state : null,
        "hasDate": transaction.hasDate ? transaction.hasDate : null,
        "externalDepartment": transaction.externalDepartment ? transaction.externalDepartment : null,
        "department": transaction.department ? transaction.department : null,
        "transactionTypeId": transaction.transactionTypeId ? transaction.transactionTypeId : null,
        "confidentialityLevelId": transaction.confidentialityLevelId ? transaction.confidentialityLevelId : null,
        "letterTypeId": transaction.letterTypeId ? transaction.letterTypeId : null,
        "priorityLevelId": transaction.priorityLevelId ? transaction.priorityLevelId : null,
        "copiesUnSeen": transaction.copiesUnSeen ? transaction.copiesUnSeen : null,
        "transactionClassificationId": transaction.transactionClassificationId ? transaction.transactionClassificationId : null,
        "forUser": transaction.forUser ? {
          "userId": transaction.forUser.userId,
          "departmentId": transaction.forUser.departmentId
        } : null
      }
      ,
      { headers: this._authService.authHeader }
    )
  }

  shareAsList(shareList){
    return this._httpClient.post(`${this.apiUrl}Transaction/ShareAsList`, shareList, { headers: this._authService.authHeader })
  }



  enterFullScreen(element){
    const el = document.querySelector(`.${element}`);
    el.classList.add('full-screen-editor');
    document.addEventListener('keydown',(event)=>{
      if(event.key === 'Escape'){
        el.classList.remove('full-screen-editor');
      }
    });
    this._toastr.success("استخدم Escape للخروج من وضع الشاشة الكاملة !")
  }

}