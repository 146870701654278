import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getbarcodeobject'
})
export class GetbarcodeobjectPipe implements PipeTransform {

  transform(item: any, detailsTransaction: any): unknown {
    let BarcodeObj;
    const date = new Date(detailsTransaction?.creationDate);
    if(item?.main){
      BarcodeObj = item;
      delete BarcodeObj['main']
    } else {
      BarcodeObj = {
        "رقم المعاملة": detailsTransaction?.transactionNumber,
        "وقت المعاملة": date.toLocaleString().split(",")[1],
        "جهة الصادر" : item.nameAr,
        "نوع المعاملة" : "صادر - نسخة"
      };
    }
    // //console.log("from pipe",BarcodeObj)
    return JSON.stringify(BarcodeObj)
  }

}
