import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appToggleClass]',
  exportAs: 'ToggleClass'
})
export class ToggleClassDirective {

  constructor(private _el: ElementRef, private _renderer: Renderer2) { }

  @HostListener('click', ['$event']) onClick($event) {
    // console.info($event);
    const _this = this._el.nativeElement,
        _content = _this.nextElementSibling,
        _allList = _this.closest('.treeView-list').children,
        _allSiblings = [],
        _elParent =  _this.parentElement;
    
        _elParent.classList.toggle('is-open');
    
        
    for(let i = 0 ; i < _allList.length -1; i++){

      if(_allList[i] !== _elParent) {
        // _allSiblings.push(_allList[i]);
        _allList[i].classList.remove('is-open');
        // _allList[i].style.maxHeight = null;
      }

      if (_content.style.maxHeight) {
        // _content.style.maxHeight = null;
      } else {
        // accordion is currently closed, so open it
        // _content.style.maxHeight = _content.scrollHeight * 2 + "px";
      }
    }
  }

}
