import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';

@Pipe({
  name: 'showElement'
})
export class ShowElementPipe implements PipeTransform {
  constructor(private authService : AuthenticationService){}

  userPremissions : any[] = this.authService.userPremissions

  transform(requiredPremisson: string, ...args: unknown[]): unknown {
    if(this.userPremissions.indexOf(requiredPremisson) > -1){
      return true;
    } else {
      return false;
    }
  }
}
